import { Injectable } from '@angular/core';
import { EmailModel } from '../shared/models/email-model';
import { ConfigService } from '../services/config.service';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { EmailRecipient } from '../shared/models/email-recipient';

@Injectable({
  providedIn: 'root'
})

export class EmailService {
  authToken: string;

  constructor(private httpClient: HttpClient, private authService: AuthService, private configService: ConfigService) { }

  public SendEmail(email: EmailModel, token: string) {

    //Setting default Field Supervisor Application Sender
    if (email.from === null || email.from === undefined || email.from.sendOnBehalf == false) {

      //Mapping sender: By default emails are send using authenticated user (Supervisor or Impersonator)
      email.from = new EmailRecipient;
      email.from.email = this.authService.user.email;
      email.from.name = this.authService.user.displayName;
      email.saveToSentFolder = true;
    }

    this.authToken = token;

    if (this.authToken == undefined) {
      //get auth token from authService
      this.authService.getAccessToken().then(token => {
        this.authToken = token;
      });
    }

    let headersToPass = new HttpHeaders();
    headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8').set('authToken', this.authToken);

    return this.httpClient.post<EmailModel>(`${this.configService.apiUrl}/v1.0/email/`, email, { headers: headersToPass }).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }
}


