import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ObsResponses } from 'src/app/shared/models/contactResponses';
import { Helper } from "src/utility/Helper";

@Component({
    selector: 'app-observation-planning',
    templateUrl: './observation-planning.component.html',
    styleUrls: ['./observation-planning.component.scss']
})
export class ObservationPlanningComponent implements OnInit {

    obsPlanning = [{
        "id": "11",
        "initialVal": 0,
        "title": "Dimension 1.1: Standard and Alignment",
        "desc": "The teacher designs clear, well-organized, sequential lessons that reflect best practice, align with the standards, and are appropriate for diverse learners.",
        "instruction": "<b>Instructional planning:</b><br><br>•Is aligned and sequenced to state standards and objectives<br><br>•Is well-organized, rigorous, and relevant<br><br>•Is paced appropriately for diverse learners<br><br>•Uses technology when applicable<br><br>•Includes real world examples tied to objective<br><br>•Provides opportunity for students to problem-solve and make decisions"
    },
    {
        "id": "12",
        "initialVal": 3,
        "title": "Dimension 1.2: Data and Assessment",
        "desc": "The teacher uses formal and informal methods to measure student progress, then manages and analyzes student data to inform instruction.",
        "instruction": "<b>Instructional planning:</b><br><br>•Includes ongoing formal or informal assessments to measure students’ knowledge and skills<br><br>•Incorporates a variety of questioning strategies to assess students’ understanding of objective<br><br>•Provides immediate feedback to ensure students’ understanding<br><br>•Analyzes data to adjust and modify instruction"
    },
    {
        "id": "13",
        "initialVal": 6,
        "title": "Dimension 1.3: Knowledge of Students",
        "desc": "Through knowledge of students and proven practices, the teacher ensures high levels of learning, social-emotional development, and achievement for all students.",
        "instruction": "<b>Instructional planning:</b><br><br>•Connects instruction to students’ prior knowledge<br><br>•Adjusts instruction to support diverse students’ needs<br><br>•Builds on students’ strengths and identifies learning gaps to improve academic success<br><br>•Utilizes students’ social/emotional/academic levels and needs to increase student engagement<br><br>•Identifies students’ learning modality to deliver instruction and increase students’ achievement"
    },
    {
        "id": "14",
        "initialVal": 9,
        "title": "Dimension 1.4: Activities",
        "desc": "The teacher plans engaging, flexible lessons that encourage higher-order thinking, persistence, and achievement.",
        "instruction": "<b>Instructional planning:</b><br><br>•Incorporates higher-order thinking skills to challenge students’ academic growth<br><br>•Facilitates groupings to maximize students’ involvement in the learning process<br><br>•Varies activities/resources/materials & technology<br><br>•Provides opportunities for students’ input on goals and learning outcomes<br><br>•Maintains group and individual accountability<br><br>•Integrates multi-discipline literature to promote literacy and content language"
    }];

    @Input() Responses: ObsResponses[] = [];
    @Output() onPlanningDataChange: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
        this.LoadResponse();
        this.onPlanningDataChange.emit(this.Responses);
    }

    EmitChildData(index: number, type: string) {
        this.onPlanningDataChange.emit(this.Responses);
        const dimensionId: string = `dimension-1.${index+1}-${type}`;
        Helper.removeCssClass(dimensionId, "validation-error");
    }

    LoadResponse() {

        let count = 0;

        if (this.obsPlanning.length <= 4 && this.Responses.length <= 0) {
            for (let index = 0; index < this.obsPlanning.length; index++) {
                const element = this.obsPlanning[index];

                count += 1;
                let obRes = new ObsResponses();
                obRes.QuestionId = count;
                obRes.ControlType = "Ranking";
                obRes.ResponseValue = "";

                count += 1;
                let obRes1 = new ObsResponses();
                obRes1.QuestionId = count;
                obRes1.ControlType = "FreeTextForm";
                obRes1.ResponseValue = "";

                count += 1;
                let obRes2 = new ObsResponses();
                obRes2.QuestionId = count;
                obRes2.ControlType = "FreeTextForm";
                obRes2.ResponseValue = "";

                this.Responses.push(obRes);
                this.Responses.push(obRes1);
                this.Responses.push(obRes2);

            }
        }
    }
}
