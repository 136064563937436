import { Assessment } from "./assessment";
import { internCertDetails } from "./internCertDetails";
import { VisitationFormat } from "./visitation-format";

export class InformalObservationState {
  isvalid: boolean;
  isdirty: boolean;
  lstObservations: any[];
  obsAssessment: Assessment;
  lstcert: internCertDetails;
  loadDuration: Function;
  getTimeIn24HourFormat: (timeIn12HourFormat: string) => string;
  fieldSupervisorDisplayName: string;
  signature: boolean;
  visitationFormatOptions: VisitationFormat[];
  observationDiscussion: string;
}
