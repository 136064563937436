import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Subject } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { Impersonation_AddToImpersonationLog_Options, Authorization_GetRoles_Options, AuthenticatedAndAuthorizedUser, Authorization_Role } from '../shared/models/authorization.model';
import { ConfigService } from '../services/config.service';
import { User } from '../shared/models/user';
import { RoleValue } from '../shared/models/role.model';

@Injectable({
  providedIn: 'root'
})

export class AuthorizationService {

  //Property authenticatedAndAuthorizedUser this is to save and preserve in the session the user and roles info
  private _authenticatedAndAuthorizedUser: AuthenticatedAndAuthorizedUser;

  get authenticatedAndAuthorizedUser(): AuthenticatedAndAuthorizedUser {
    this._authenticatedAndAuthorizedUser = JSON.parse(sessionStorage.getItem('authenticatedAndAuthorizedUser'));
    return this._authenticatedAndAuthorizedUser;
  }

  set authenticatedAndAuthorizedUser(value: AuthenticatedAndAuthorizedUser) {
    if (value != null) {
      this._authenticatedAndAuthorizedUser = value;
      sessionStorage.setItem('authenticatedAndAuthorizedUser', JSON.stringify(this._authenticatedAndAuthorizedUser));
    }
  }

  apiUrl = '';
  EmailServerURL = '';
  AuthServerURL = '';

  //constructor
  constructor(private httpClient: HttpClient, private configSvc: ConfigService) {
    this.apiUrl = this.configSvc.apiUrl;
    this.EmailServerURL = this.configSvc.emailServerURL;
    this.AuthServerURL = this.configSvc.authServerURL;

  }

  //This method uses User Azure Active directory info to get roles from Prince
  public getAuthorization(user: User): AuthenticatedAndAuthorizedUser {

    let authUser = new AuthenticatedAndAuthorizedUser();

    if (user != null) {
      authUser.FirstName = user.givenName;
      authUser.LastName = user.surname;
      authUser.UserGUID = user.id;
      authUser.EmailAddress = user.email;

      var _Authorization_GetRoles_Options: Authorization_GetRoles_Options = new Authorization_GetRoles_Options();
      _Authorization_GetRoles_Options.AuthenticatedEmailAddress = authUser.EmailAddress;

      this.Authorization_GetRoles(_Authorization_GetRoles_Options).subscribe(roles => {
        authUser.Authorization_Roles = roles
        var coachImpersonatorRole = roles.find(x => x.AuthorizationRoleName == 'Coach_Impersonator');
        var internImpersonatorRole = roles.find(x => x.AuthorizationRoleName == 'Intern_Impersonator');

        //TODO: Removed this code causing issues with login Process
        //var advisorRole = roles.find(x => x.AuthorizationRoleName == 'Advisor');

        authUser.IsCoachImpersonator = coachImpersonatorRole != null ? coachImpersonatorRole.AuthorizationRoleID != null : false;
        authUser.IsInternImpersonator = internImpersonatorRole != null ? internImpersonatorRole.AuthorizationRoleID != null : false;
        this.authenticatedAndAuthorizedUser = authUser;

        //TODO: Removed this code causing issues with login Process
        //if (advisorRole == null)
        //{
        //    alert("User Login " + authUser.EmailAddress + " is not authorized to access Advisor Portal.");
        //    setTimeout(function () { this.authUser = null; window.location.href = "https://login.microsoftonline.com/common/oauth2/v2.0/logout"; }, 750);
        //}



      });
      this.Authorization_GetUserID(_Authorization_GetRoles_Options).subscribe(userId => {
        authUser.UserID = userId;
        if (this.authenticatedAndAuthorizedUser != null) {
          this.authenticatedAndAuthorizedUser.UserID = userId;
        }

      });
    }
    return this.authenticatedAndAuthorizedUser;
  }

  //Client method to get roles from Prince database
  public Authorization_GetRoles(Authorization_GetRoles_Options: Authorization_GetRoles_Options) {
    return this.httpClient.post<any>(`${this.apiUrl}/v1.0/Authorization/GetRoles`, Authorization_GetRoles_Options).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public Authorization_GetUserID(Authorization_GetRoles_Options: Authorization_GetRoles_Options) {
    return this.httpClient.post<any>(`${this.apiUrl}/v1.0/Authorization/GetUserID`, Authorization_GetRoles_Options).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }


  //This method add impersonation log to Prince database
  public Impersonation_AddToImpersonationLog(Impersonation_AddToImpersonationLog_Options: Impersonation_AddToImpersonationLog_Options) {
    return this.httpClient.post<any>(`${this.apiUrl}/v1.0/Impersonation/AddToImpersonationLog`, Impersonation_AddToImpersonationLog_Options).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }


  public UserInRole(roleValue: RoleValue): boolean {
    if (!this.authenticatedAndAuthorizedUser) {
      return false;
    }
    var index: number = this.authenticatedAndAuthorizedUser.Authorization_Roles.findIndex(role => +role.AuthorizationRoleID == roleValue);
    return (index > -1 ? true : false);
  }

  public UserInRoles(roleValues: RoleValue[]): boolean {
    if (!this.authenticatedAndAuthorizedUser) {
      return false;
    }
    const allowedStatusRoles = this.authenticatedAndAuthorizedUser.Authorization_Roles.filter(role => roleValues.includes(+role.AuthorizationRoleID));
    return (allowedStatusRoles.length > 0);
  }

}
