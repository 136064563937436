import { observations } from '../models/observations';

export class ContactScheduleCalculator {
  constructor() {
  }
  // It is assumed that the start of an observation is the day after the dealine of the previous observation.
  // The first observation is assumed to start on the start date of the certification area.
  // This function calculates the start date of each observation based on the deadline of the previous observation.
  calculateObservationStartDates(observations: observations[], certAreaStartDate: Date): observations[] {
    for (let i = 0; i < observations.length; i++) {
      let observation = observations[i];
      const previousObservation = i > 0 ? observations[i - 1] : undefined;

      let startDate: Date;

      if (previousObservation) {
        startDate = this.addDays( new Date(previousObservation.DeadLine), 1);
      } else {
        startDate = certAreaStartDate; // Set a default start date if there is no previous observation
      }

      observation.StartDate = startDate;
    }
    return observations;
  }

  private addDays(inputDate: Date, days: number) {
    let date = new Date(inputDate);
    date.setDate(date.getDate() + days);
    return date;
  }
}


