import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ObsResponses } from 'src/app/shared/models/contactResponses';
import { Helper } from 'src/utility/Helper';

@Component({
    selector: 'app-observation-instruction',
    templateUrl: './observation-instruction.component.html',
    styleUrls: ['./observation-instruction.component.scss']
})
export class ObservationInstructionComponent implements OnInit {

    obsPlanning = [{
        "id": "21",
        "initialVal": 0,
        "title": "Dimension 2.1: Achieving Expectations",
        "desc": "The teacher supports all learners in their pursuit of high levels of academic and social-emotional success.",
        "instruction": "<b>The teacher:</b><br><br>•Creates instructional opportunities that challenge students to achieve academic success<br><br>•Monitors evidence of students’ responses for students’ mastery of objectives<br><br>•Analyzes students’ misunderstandings to adjust further instruction<br><br>•Executes lessons that create the expectation for students to monitor their progress"
    },
    {
        "id": "22",
        "initialVal": 3,
        "title": "Dimension 2.2: Content Knowledge and Expertise",
        "desc": "The teacher uses content and pedagogical expertise to design and execute lessons aligned with state standards, related content, and student needs.",
        "instruction": "<b>The teacher:</b><br><br>•Conveys accurate content knowledge in multiple contexts<br><br>•Integrates learning objectives to other disciplines and real-world experiences<br><br>•Monitors students’ reactions and misunderstandings to adjust teaching techniques<br><br>•Incorporates critical-thinking questioning strategies and activities to increase depth of students’ responses<br><br>•Links and connects instruction to prior knowledge"
    },
    {
        "id": "23",
        "initialVal": 8,
        "title": "Dimension 2.3: Communication",
        "desc": "The teacher clearly and accurately communicates to support persistence, deeper learning, and effective effort.",
        "instruction": "<b>The teacher:</b><br><br>•Posts and clearly states objectives<br><br>•Creates a climate where students are comfortable to participate in the learning process<br><br>•Responds to misunderstandings by providing clear and concise clarifications<br><br>•Provides clear and correct verbal and written communication<br><br>•Allows sufficient wait time when questioning students<br><br>•Utilizes technology and visual tools to further clarify objective and reinforce learning"
    },
    {
        "id": "24",
        "initialVal": 11,
        "title": "Dimension 2.4: Differentiation",
        "desc": "The teacher differentiates instruction, aligning methods and techniques to diverse student needs.",
        "instruction": "<b>The teacher:</b><br><br>•Provides individualized instruction to meet students’ needs<br><br>•Monitors students’ participation, engagement, and performance to measure effectiveness of lesson delivery<br><br>•Responds to students’ confusion and disengagement by differentiating and adjusting methods of delivery<br><br>•Plans alternative activities for students needing remediation or accelerated learning activities"
    },
    {
        "id": "25",
        "initialVal": 14,
        "title": "Dimension 2.5: Monitor and Adjust",
        "desc": "The teacher formally and informally collects, analyzes, and uses student progress data and makes needed lesson adjustments.",
        "instruction": "<b>The teacher:</b><br><br>•Monitors and adjusts instruction and activities to meet students’ social/emotional/academic needs<br><br>•Provides adjustments to maximize students’ learning<br><br>•Monitors students’ engagement to ensure active participation<br><br>•Uses probing questioning strategies to check for understanding and students’ comprehension level of objectives"
    }];

    @Input() Responses: ObsResponses[] = [];
    @Output() onInstructionDataChange: EventEmitter<any> = new EventEmitter<any>();
    private warmupActivityChecked: boolean;
    private exitActivityChecked: boolean;

    constructor() { }

    ngOnInit() {
        this.LoadResponse();
        this.onInstructionDataChange.emit(this.Responses);
    }

    EmitChildData(index: number, type: string) {
        this.onInstructionDataChange.emit(this.Responses);        
        const dimensionId: string = `dimension-2.${index+1}-${type}`;
        Helper.removeCssClass(dimensionId, "validation-error");
    }

    LoadResponse() {

        let count = 12;

        if (this.obsPlanning.length <= 5 && this.Responses.length <= 0) {
            for (let index = 0; index < this.obsPlanning.length; index++) {
                const element = this.obsPlanning[index];

                count += 1;
                let obRes = new ObsResponses();
                obRes.QuestionId = count;
                obRes.ControlType = "Ranking";
                obRes.ResponseValue = "";
                this.Responses.push(obRes);

                //For this specific instruction add static 2 responses
                if (element.id == "22") {

                    count += 1;
                    let warm_up = new ObsResponses();
                    warm_up.QuestionId = count;
                    warm_up.ControlType = "FreeTextForm";
                    warm_up.ResponseValue = "";
                    this.Responses.push(warm_up);
                    

                    count += 1;
                    let activities = new ObsResponses();
                    activities.QuestionId = count;
                    activities.ControlType = "FreeTextForm";
                    activities.ResponseValue = "";                   
                    this.Responses.push(activities);
                    
                }

                count += 1;
                let obRes1 = new ObsResponses();
                obRes1.QuestionId = count;
                obRes1.ControlType = "FreeTextForm";
                obRes1.ResponseValue = "";

                count += 1;
                let obRes2 = new ObsResponses();
                obRes2.QuestionId = count;
                obRes2.ControlType = "FreeTextForm";
                obRes2.ResponseValue = "";

               
                this.Responses.push(obRes1);
                this.Responses.push(obRes2);

                
            }
        }     
        
        if(this.Responses.length > 0) {
            if(this.Responses.find(el => el.Question === "Warm-up") != undefined) {
                const warmUpActivityResponse = this.Responses.find(el => el.Question === "Warm-up").ResponseValue
                this.warmupActivityChecked = warmUpActivityResponse == "true" || parseInt(warmUpActivityResponse) > 0;
            }
            if(this.Responses.find(el => el.Question === "Activities") != undefined) {
                const exitActivityResponse = this.Responses.find(el => el.Question === "Activities").ResponseValue
                this.exitActivityChecked = exitActivityResponse == "true" || parseInt(exitActivityResponse) > 0;
            }
        }
    }

    private onActivity_Changed(isChecked: boolean, index: number) {
        this.Responses[index].ResponseValue = isChecked.toString();
    }
}
