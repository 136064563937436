// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-row {
  background-color: #efefef;
  padding-top: 10px;
  height: 41px;
  width: 100%;
}
.banner-row .banner-text {
  font-size: 1.3rem;
  font-weight: bold;
  color: #324758;
  text-transform: uppercase;
  font-family: serif;
}

.conference-detail-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.conference-detail-row mat-form-field:not(:first-of-type) {
  margin-left: 1rem;
}

.location-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.location-row .campus {
  margin-left: 2rem;
}

.collaborated-with-section > div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.notes-section span {
  margin-top: 0;
  margin-bottom: 0;
}

.acknowledgement-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

mat-form-field {
  margin-top: 1rem;
  width: 100%;
}

.validation-error {
  border: 1px solid #f00 !important;
  padding: 0 5px !important;
}

.validation-error-text {
  color: #f00 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/intern/conference/conference.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACI,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;EACA,kBAAA;AACR;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAEI;EACI,iBAAA;AAAR;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;AADJ;AAGI;EACI,iBAAA;AADR;;AAKA;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,SAAA;AAFJ;;AAMI;EACI,aAAA;EACA,gBAAA;AAHR;;AAOA;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;AAJJ;;AAOA;EACI,gBAAA;EACA,WAAA;AAJJ;;AAOA;EACI,iCAAA;EACA,yBAAA;AAJJ;;AAOA;EACI,sBAAA;AAJJ","sourcesContent":[".banner-row {\n    background-color: #efefef;\n    padding-top:10px;\n    height: 41px;\n    width: 100%;\n\n    .banner-text {\n        font-size: 1.3rem; \n        font-weight: bold;\n        color: #324758;\n        text-transform: uppercase;\n        font-family:serif;\n    }\n}\n\n.conference-detail-row {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n\n    mat-form-field:not(:first-of-type) {\n        margin-left: 1rem;\n    }\n}\n\n.location-row {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    margin-top: 0.5rem;\n    margin-bottom: 0.5rem;\n\n    .campus {\n        margin-left: 2rem;\n    }\n}\n\n.collaborated-with-section > div {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    gap: 10px;\n}\n\n.notes-section {\n    span {\n        margin-top: 0;\n        margin-bottom: 0;\n    }\n}\n\n.acknowledgement-row {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    margin-top: 0.5rem;\n    margin-bottom: 0.5rem;\n}\n\nmat-form-field {\n    margin-top: 1rem;\n    width: calc(100%);\n}\n\n.validation-error {\n    border: 1px solid #f00 !important;\n    padding: 0 5px !important;\n}\n\n.validation-error-text {\n    color: #f00 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
