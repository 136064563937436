import { internDetails } from './internDetails';
import { recommendation } from './recommendation';
import { campus } from './campus';
import { certprogram } from './certprogram';
import { fsContacts } from './fsContacts';

export class internCertDetails{
    Intern : internDetails = null;
    Recommendations:recommendation[];
    Campus:campus= null;
    Certificates:certprogram[];
    FsContacts:fsContacts[]=null;
}
