import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthorizationService } from '../services/authorization.service';
import { Role, RoleValue } from '../shared/models/role.model';
import { AdminService } from '../services/admin.service';
import { GlobalService } from '../services/global.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LockoutGuard  {
  redirectUser: boolean = false;

  constructor(private router: Router,
    private authorizationService: AuthorizationService,
    private authService: AuthService,
    private adminSvc: AdminService,
    private globalService: GlobalService) {
  }

  //The canActivate returns a Promise because we need to wait until all calls to the server complete.
  //We resolve the promise after all the http calls are complete and we know the boolean value. 
  //After resolve is called, the router resumes processing request.
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((resolve, reject) => {
    
      // Impersonating user is allowed to proceed.
      if(this.globalService.impersonated == true){
        resolve(true);
        return;
      }

      Promise.all([
        this.adminSvc.getAppSettingValue("FSPortal", "LockoutStartDate"),
        this.adminSvc.getAppSettingValue("FSPortal", "LockoutEndDate")
      ])
        .then(resultArray => {
          try {

            let lockoutStartDate: Date = new Date(resultArray[0].value);
            let lockoutEndDate: Date = new Date(resultArray[1].value);

            let withinLockoutDates:boolean = this.withinLockoutDates(lockoutStartDate, lockoutEndDate);
            
            if (this.cannotBypassLockout() && withinLockoutDates) {
              this.router.navigate(['lockout-notification']);
              resolve(false);
              return;
            }

            if (this.authorizationService.UserInRole(RoleValue.Coach_Impersonator)) {
              this.router.navigate(['select-supervisor']);
            }

            resolve(true);
          }
          catch (error) {
            console.log(`error processing lockout ${error}`);
            resolve(true);
          }
        })
        .catch((error) => {
          console.log(error);
          resolve(true);
        })
    });
  }

  withinLockoutDates(lockoutStartDate:Date, lockoutEndDate:Date): boolean {
    let today: Date = new Date();
    
    return lockoutStartDate != null &&
      lockoutEndDate != null &&
      today >= lockoutStartDate &&
      lockoutEndDate >= today;
  }

  cannotBypassLockout(): boolean {
    if (this.authService.authenticated) {
      return this.shouldRedirectUser(this.authService.user.roles);
    }
    return false;
  }

  shouldRedirectUser(userRoles: Role[]): boolean {

    let restrictedRoles =
      [RoleValue.Coach,
      RoleValue.Field_Supervisor,
      RoleValue.Intern_Impersonator];

    let remainingRoles = this.removeRestrictedRoles(userRoles, restrictedRoles);

    return remainingRoles.length == 0;
  }

  removeRestrictedRoles(userRoles: Role[], restrictedRoles: RoleValue[]): Role[] {

    restrictedRoles.forEach(restrictedRole => {
      let i = this.getItemIndex(userRoles, restrictedRole);
      if (i > -1) {
        userRoles.splice(i, 1);
      }
    });

    return userRoles;
  }

  public getItemIndex(userRoles: Role[], roleValue: RoleValue): number {
    var index: number = userRoles.findIndex(role => +role.AuthorizationRoleID == roleValue);
    return index;
  }
}
