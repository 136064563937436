import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-observation-form-error-sheet',
  templateUrl: './observation-form-error-sheet.component.html',
    styleUrls: ['./observation-form-error-sheet.component.scss']
})
export class ObservationFormErrorSheetComponent {

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
        this.itemList = data.messages;
        this.title = data.title;
    }

    ngOnInit() {
    }

    title: string = '';
    itemList: string[] = [];
}
