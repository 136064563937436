import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from 'src/app/services/email.service';
import { FSContactsService } from 'src/app/services/fs-contacts.service';
import { InternNoteService } from 'src/app/services/intern-note.service';
import { InternService } from 'src/app/services/intern.service';
import { ContactTypeEnum } from 'src/app/shared/enums/contact-type.enum';
import { ObsAlertTypeEnum } from 'src/app/shared/enums/obs-alert-type.enum';
import { Assessment } from 'src/app/shared/models/assessment';
import { AssessmentOption } from 'src/app/shared/models/assessmentOption';
import { CandidateNote } from 'src/app/shared/models/candidateNote';
import { ContactRecipientType } from 'src/app/shared/models/contact-recipient-type';
import { contactAlert } from 'src/app/shared/models/contactAlert';
import { ObsResponses } from 'src/app/shared/models/contactResponses';
import { EmailModel } from 'src/app/shared/models/email-model';
import { EmailRecipient } from 'src/app/shared/models/email-recipient';
import { internCertDetails } from 'src/app/shared/models/internCertDetails';
import { MileageOption } from 'src/app/shared/models/mileageOption';
import { Helper } from "src/utility/Helper";
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { ObservationService } from '../../../services/observation.service';
import { AssessmentAttachment } from '../../../shared/models/assessment-attachment.model';
import { FieldSupervisor } from '../../../shared/models/fsupervisor.model';
import { ObservationFormErrorSheetComponent } from '../observation-form-error-sheet/observation-form-error-sheet.component';
import { AlertType } from './../../../shared/models/alertType';
import { ContactType } from './../../../shared/models/contactType';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DayEnum } from 'src/app/shared/enums/day.enum';
import { ObsQuestionEnum } from 'src/app/shared/enums/obs-questions.enum';
import { ObsQuestionGrowthStateEnum } from 'src/app/shared/enums/obs-questions-growth-state.enum';
import { VisitationFormat } from '../../../shared/models/visitation-format';
import { InformalObservationState } from 'src/app/shared/models/informal-observation-state.component';
import { VisitationFormatEnum } from 'src/app/shared/enums/visitation-format.enum';
import { ObsStatusEnum } from 'src/app/shared/enums/obs-Status.enum';
import { formatDate } from '@angular/common';
import { ConferenceState } from 'src/app/shared/models/conference-state.model';
import { RecipientTypeEnum } from 'src/app/shared/enums/recipient-type.enum';
import { MileageOptionsEnum } from 'src/app/shared/enums/mileage-options.enum';

@Component({
  selector: 'app-observation-details',
  templateUrl: './observation-details.component.html',
  styleUrls: ['./observation-details.component.scss']
})
export class ObservationDetailsComponent implements OnInit {
  @ViewChild('StartTime') StartTime: ElementRef<HTMLSelectElement>;
  @ViewChild('EndTime') EndTime: ElementRef<HTMLSelectElement>;
  @ViewChild('ObsDuration') ObsDuration: ElementRef<HTMLInputElement>;
  @ViewChild('ContactType') ContactType: ElementRef<HTMLSelectElement>;
  @ViewChild('ObservationNumber') ObsNumber: ElementRef<HTMLSelectElement>;
  @ViewChild('digitalSignCheckbox') DigitalSignCheckbox: ElementRef<HTMLInputElement>;

  fieldSupervisor: FieldSupervisor;
  internDetailsLoaded = false;
  internId: string;
  lstcert: internCertDetails;
  showInformalObservation: boolean;
  showObservation: boolean = true;
  showConference: boolean;
  showAlertExp: boolean = false;
  preConferenceStartTimes = [];
  preConferenceEndTimes = [];
  postConferenceStartTimes = [];
  postConferenceEndTimes = [];
  obsAssessment: Assessment = new Assessment();
  lstObservations = [];
  isTexasObs: any;
  assessmentId: any;
  completed: string;
  formValidForSave: boolean = true;
  formValidForFinalize: boolean = true;
  isClassRoomSupport: boolean = false;

  PlanningResponses: ObsResponses[] = [];
  InstructionResponses: ObsResponses[] = [];
  LearningResponses: ObsResponses[] = [];
  ProfessionalResponses: ObsResponses[] = [];

  totPendingReq = new ObsResponses();
  strengths = new ObsResponses();
  areasOfGrowth = new ObsResponses();
  actionableSteps = new ObsResponses();
  otherNotes = new ObsResponses();
  impression = new ObsResponses();
  conPreDate = new ObsResponses();
  conPreStartTime = new ObsResponses();
  conPreEndTime = new ObsResponses();
  conPostDate = new ObsResponses();
  conPostStartTime = new ObsResponses();
  conPostEndTime = new ObsResponses();
  contactInfo = new ObsResponses();
  responseValidationFeedback: string[] = [];
  idToElementNameMap: { [elementID: number]: string };
  evidenceIdToQuestionId: { [elementID: number]: number };
  informalObservationState: InformalObservationState;
  conferenceState: ConferenceState;

  private emailHtmlContent: string;
  private token = '';
  private RECIPIENT_EMAIL_ADDRESS = 'classroomsupport@teachersoftomorrow.org';
  private RECIPIENT_NAME = 'Classroom Support'
  private TX_CC_EMAIL_ADDRESS = 'c&c@texasteachers.org';
  private CERTIFICATION_EMAIL_ADDRESS = 'certification@teachersoftomorrow.org';
  private NOREPLY_EMAIL_ADDRESS = 'noreply@teachersoftomorrow.org';
  private alertName: string;
  observationStartTime: string;
  observationEndTime: string;
  contactTypeList: Array<ContactType>;
  contactRecipientTypeList: Array<ContactRecipientType>;
  alertTypeList: Array<AlertType>;
  mileageOptions: Array<MileageOption>;
  overallAssessmentOptions: Array<AssessmentOption>;
  rawOverallAssessmentOptions: Array<AssessmentOption>;
  visitationFormatOptions: Array<VisitationFormat>;
  saveButtonClicked: boolean = false;
  finishButtonClicked: boolean = false;

  public attachment: AssessmentAttachment;
  attachmentFilename: string;
  showContactRecipientTypesDropdown: boolean;
  contactRecipientTypesListLoaded: boolean;
  showMileageDropdownForMeetAndGreet: boolean;
  hasOverallImpression: boolean;
  newObservation: boolean;
  showAlertDropdown: boolean;
  loadSpinner: boolean;
  blockObservationCreation: boolean = false;
  blockInformalObservationCreation: boolean = false;
  blockConferenceCreation: boolean = false;
  disableSaveButton: boolean = false;
  showInPersonError: boolean;
  formalObservationNumberText: string;
  digitalSign: boolean;
  duration: number;
  readonly minimumDuration: number = 15;
  cancelBackButtonText = 'Cancel';
  legendText: string;
  legendBeginningText: string;
  isFullTimeFieldSupervisor: boolean;
  showMileageField: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private internService: InternService,
    private observationService: ObservationService,
    private fsContactsService: FSContactsService,
    private authService: AuthService,
    private globalService: GlobalService,
    private emailService: EmailService,
    private internNoteService: InternNoteService,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    if (!this.authService.authenticated) {
      this.router.navigate(['auth']);
    }

    if (this.authService.authenticated) {
      if (this.globalService.supervisor != undefined) {
        this.fieldSupervisor = this.globalService.supervisor;
        this.isFullTimeFieldSupervisor = this.fieldSupervisor.isFullTimeEmployee;
      }
    }

    const today = new Date().toISOString();

    // initialize alerts type dropdown
    this.fsContactsService.GetAlertTypes().subscribe(data => {
      this.alertTypeList = data;
    })

    // initialize contact recipient types dropdown
    this.fsContactsService.GetContactRecipientTypes().subscribe(data => {
      this.contactRecipientTypeList = data;
      this.contactRecipientTypesListLoaded = true;
    })

    // initialize mileage options dropdown
    this.observationService.GetMileageOptions().subscribe(data => {
      this.mileageOptions = data.filter(mo => mo.id != MileageOptionsEnum.VirtualObservation);
    })

    // initialize assessment options dropdown
    this.observationService.GetAssessmentOptions().subscribe(data => {
      this.rawOverallAssessmentOptions = data.filter(option => option.effectiveEnd > today);
      this.overallAssessmentOptions = this.rawOverallAssessmentOptions.map(option => {
        if (option.description === 'Not Selected') {
          option.sortOrder = 0;
        }
        if (option.description === 'Improvement Needed') {
          option.sortOrder = 1;
        }
        if (option.description === 'Developing') {
          option.sortOrder = 2;
        }
        if (option.description === 'Proficient') {
          option.sortOrder = 3;
        }
        if (option.description === 'Accomplished') {
          option.sortOrder = 4;
        }
        return option;
      })

      this.overallAssessmentOptions.sort((a, b) => a.sortOrder - b.sortOrder);
    })

    // initialize Visitation Format options dropdown
    this.observationService.GetVisitationFormatOptions().subscribe(data => {
      this.visitationFormatOptions = data;
    });

    this.authService.getAccessToken().then(token => {
      this.token = token;
    });

    this.internId = this.activatedRoute.snapshot.paramMap.get('id');
    this.assessmentId = this.activatedRoute.snapshot.paramMap.get('assesst');
    this.completed = this.activatedRoute.snapshot.paramMap.get('completed');
    this.loadPreConferenceStartTimeDropdown();
    this.loadPostConferenceStartTimeDropdown();
    this.getInternsDetails();
    this.cancelBackButtonText = this.completed == 'true' ? 'BACK' : 'CANCEL'

    var roles = JSON.parse(localStorage.getItem("fsRoles"));
    if (!!roles) {
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].AuthorizationRoleID == 11 || roles[i].AuthorizationRoleID == 12) { // Classroom support can edit even completed observations
          this.isClassRoomSupport = true;
          break;
        }
      }
    }
  }

  private isMileageFieldRequired() {
    const isObservationInPerson = 
        this.obsAssessment.ContactTypeId === ContactTypeEnum.Observation && 
        this.obsAssessment.observation.VisitationFormatId === VisitationFormatEnum.InPerson
        && this.isFullTimeFieldSupervisor;

    const isMeetAndGreetFullTimeSupervisor = 
        this.obsAssessment.ContactTypeId === ContactTypeEnum.MeetAndGreet && 
        this.isFullTimeFieldSupervisor;

    return isObservationInPerson || isMeetAndGreetFullTimeSupervisor;
}

  mapElementNameToQuestionId(isTexas: boolean) {

    if (isTexas) {

      this.idToElementNameMap = {
        1: 'Dimension 1.1',
        4: 'Dimension 1.2',
        7: 'Dimension 1.3',
        10: 'Dimension 1.4',
        13: 'Dimension 2.1',
        16: 'Dimension 2.2',
        21: 'Dimension 2.3',
        24: 'Dimension 2.4',
        27: 'Dimension 2.5',
        30: 'Dimension 3.1',
        33: 'Dimension 3.2',
        36: 'Dimension 3.3',
        39: 'Dimension 4.1',
        42: 'Dimension 4.2',
        45: 'Dimension 4.3',
        48: 'Dimension 4.4',
        51: 'Teachers of Tomorrow Pending Requirements',
        52: 'Overall Impression',
        53: 'Pre-conference date',
        54: 'Pre-conference start time',
        55: 'Pre-conference end time',
        56: 'Post-conference date',
        57: 'Post-conference start time',
        58: 'Post-conference end time',
        59: 'Contact information',
        60: 'Strengths',
        61: 'Areas of Growth',
        62: 'Actionable Steps',
        63: 'Other Notes'
      };
      this.evidenceIdToQuestionId = {
        2: 1,  //1: 'Dimension 1.1',
        5: 4,  //4: 'Dimension 1.2',
        8: 7,  //7: 'Dimension 1.3',
        11: 10, //10: 'Dimension 1.4',
        14: 13, //13: 'Dimension 2.1',
        19: 16, //16: 'Dimension 2.2',
        22: 21, //21: 'Dimension 2.3',
        25: 24, //24: 'Dimension 2.4',
        28: 27, //27: 'Dimension 2.5',
        31: 30, //30: 'Dimension 3.1',
        34: 33, //33: 'Dimension 3.2',
        37: 36, //36: 'Dimension 3.3',
        40: 39, //39: 'Dimension 4.1',
        43: 42, //42: 'Dimension 4.2',
        46: 45, //45: 'Dimension 4.3',
        49: 48, //48: 'Dimension 4.4',
      };
    }
    else {
      this.idToElementNameMap = {
        1: 'Overall impression',
        2: 'Pre-conference date',
        3: 'Pre-conference start time',
        4: 'Pre-conference end time',
        5: 'Post-conference date',
        6: 'Post-conference start time',
        7: 'Post-conference end time',
        8: 'Contact information',
        51: 'Teachers of Tomorrow Pending Requirements',
        60: 'Strengths',
        61: 'Areas of Growth',
        62: 'Actionable Steps',
        63: 'Other Notes'
      };
    }
  }

  storeAttachment(attachment: AssessmentAttachment) {
    this.attachmentFilename = attachment ? attachment.filename : null;
  }

  public async ngAfterViewInit(): Promise<void> {
    // after get Observation and display them try to show duration
    await this.delay(3000);

    this.loadDuration();
    if (this.DigitalSignCheckbox) {
      this.DigitalSignCheckbox.nativeElement.checked = this.completed == 'true';
    }

    this.formValidForSave = true;
    this.formValidForFinalize = true;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getInternsDetails() {
    this.lstcert = new internCertDetails;
    this.internService.getInternDetailsById(this.internId, this.fieldSupervisor.FieldSupervisorId).subscribe(data => {
      this.lstcert = data;
      this.isTexasObs = this.lstcert.Intern.profileState.toLowerCase() === 'texas';
      this.LoadObservations();
      this.internDetailsLoaded = true;
      this.completed = sessionStorage.getItem(this.assessmentId); // get value from storage so they can't change the url parameter to enable edit

      // fetching the assessment details by id, if this is in edit mode.
      if (this.assessmentId) {
        this.observationService.GetAssessment(this.assessmentId).subscribe(data => {
          this.obsAssessment = data;
          this.attachment = new AssessmentAttachment(this.obsAssessment.observation.AttachmentFilename, this.obsAssessment.AssessmentId);

          this.LoadEditResponse();

          this.showMileageField = this.isMileageFieldRequired();

          if (this.obsAssessment.Alert == null) {
            this.obsAssessment.Alert = new contactAlert();
          } else {
            this.showAlertExp = true;
            if (this.obsAssessment.Alert.AlertTypeId == 0) {
              this.showAlertExp = false;
            }
          }

          if (this.conPreEndTime) {
            this.loadPreConferenceEndTimeDropdown(this.conPreEndTime.ResponseValue)
          }

          if (this.conPostEndTime) {
            this.loadPostConferenceEndTimeDropdown(this.conPostEndTime.ResponseValue);
          }

          if (this.obsAssessment.ContactTypeId != null) {
            this.loadContactType(this.obsAssessment.ContactTypeId);
          }
          
          if(this.obsAssessment.ContactTypeId == ContactTypeEnum.InformalObservation) {
            return this.loadInformalObservationState();
          }
          
          if(this.obsAssessment.ContactTypeId == ContactTypeEnum.Conference) {
            return this.loadConferenceState();
          }
        });
      }
    });

    if (this.obsAssessment.ContactTypeId != null) {
      this.loadContactType(this.obsAssessment.ContactTypeId);
    }
  }

  LoadEditResponse() {
    this.observationStartTime = this.getTimeIn24HourFormat(this.obsAssessment.observation.StartTime);
    this.observationEndTime = this.getTimeIn24HourFormat(this.obsAssessment.observation.EndTime);

    let observationResponses: ObsResponses[] = this.obsAssessment.observation.Responses;

    if (observationResponses && observationResponses.length == 0) {
      return;
    }

    if (this.isTexasObs) {
      this.LoadResponsesForTexas();
    }
    else {
      this.LoadResponsesForExpansionStates()
    }
  }

  private LoadResponsesForTexas() {

    let newResponse = [];
    let observationResponses: ObsResponses[] = this.obsAssessment.observation.Responses;

    for (let index = 0; index < 12; index++) {
      if (observationResponses.length > index) {
        newResponse.push(observationResponses[index]);
      }
    }
    this.PlanningResponses = newResponse;

    newResponse = [];
    for (let index = 12; index < 29; index++) {
      if (observationResponses.length > index) {
        newResponse.push(observationResponses[index]);
      }
    }
    this.InstructionResponses = newResponse;

    newResponse = [];
    for (let index = 29; index < 38; index++) {
      if (observationResponses.length > index) {
        newResponse.push(observationResponses[index]);
      }
    }
    this.LearningResponses = newResponse;

    newResponse = [];
    for (let index = 38; index < 50; index++) {
      if (observationResponses.length > index) {
        newResponse.push(observationResponses[index]);
      }
    }
    this.ProfessionalResponses = newResponse;

    this.hasOverallImpression = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.OverallImpression)?.ResponseValue ? true : false;

    try {
      this.totPendingReq = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.TeachersOfTomorrowPendingRequirements) ?? new ObsResponses();
      if (this.hasOverallImpression) {
        this.impression = observationResponses[ObsQuestionEnum.OverallImpression - 1];
      }
      else {
        this.strengths = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.Strengths) ?? new ObsResponses();
        this.areasOfGrowth = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.AreasOfGrowth) ?? new ObsResponses();
        this.actionableSteps = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.ActionableSteps) ?? new ObsResponses();
        this.otherNotes = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.OtherNotes) ?? new ObsResponses();
      }
      this.conPreDate = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.PreConferenceDate) ?? new ObsResponses();
      this.conPreStartTime = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.PreStartTime) ?? new ObsResponses();
      this.conPreEndTime = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.PreEndTime) ?? new ObsResponses();
      this.conPostDate = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.PostConferenceDate) ?? new ObsResponses();
      this.conPostStartTime = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.PostStartTime) ?? new ObsResponses();
      this.conPostEndTime = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.PostEndTime) ?? new ObsResponses();
      this.contactInfo = observationResponses.find(response => response.QuestionId == ObsQuestionEnum.Contact) ?? new ObsResponses();
    } catch (e) {
      console.log("Error getting responses ", observationResponses);
      if (this.hasOverallImpression) {
        this.impression = new ObsResponses();
      }
      else {
        this.totPendingReq = new ObsResponses();
        this.strengths = new ObsResponses();
        this.areasOfGrowth = new ObsResponses();
        this.actionableSteps = new ObsResponses();
        this.otherNotes = new ObsResponses();
      }
      this.conPreDate = new ObsResponses();
      this.conPreStartTime = new ObsResponses();
      this.conPreEndTime = new ObsResponses();
      this.conPostDate = new ObsResponses();
      this.conPostStartTime = new ObsResponses();
      this.conPostEndTime = new ObsResponses();
      this.contactInfo = new ObsResponses();
    }
  }

  private LoadResponsesForExpansionStates() {
    let observationResponses: ObsResponses[] = this.obsAssessment.observation.Responses;
    this.hasOverallImpression = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.OverallImpression)?.ResponseValue ? true : false;
    try {
      if (this.hasOverallImpression) {
        this.impression = observationResponses[ObsQuestionGrowthStateEnum.OverallImpression - 1];
      }
      else {
        this.totPendingReq = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.TeachersOfTomorrowPendingRequirements) ?? new ObsResponses();
        this.strengths = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.Strengths) ?? new ObsResponses();
        this.areasOfGrowth = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.AreasOfGrowth) ?? new ObsResponses();
        this.actionableSteps = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.ActionableSteps) ?? new ObsResponses();
        this.otherNotes = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.OtherNotes) ?? new ObsResponses();
      }
      this.conPreDate = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.PreConferenceDate) ?? new ObsResponses();
      this.conPreStartTime = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.PreStartTime) ?? new ObsResponses();
      this.conPreEndTime = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.PreEndTime) ?? new ObsResponses();
      this.conPostDate = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.PostConferenceDate) ?? new ObsResponses();
      this.conPostStartTime = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.PostStartTime) ?? new ObsResponses();
      this.conPostEndTime = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.PostEndTime) ?? new ObsResponses();
      this.contactInfo = observationResponses.find(response => response.QuestionId == ObsQuestionGrowthStateEnum.Contact) ?? new ObsResponses();
    } catch (e) {
      console.log("Error getting responses ", observationResponses);
      if (this.hasOverallImpression) {
        this.impression = new ObsResponses();
      }
      else {
        this.totPendingReq = new ObsResponses();
        this.strengths = new ObsResponses();
        this.areasOfGrowth = new ObsResponses();
        this.actionableSteps = new ObsResponses();
        this.otherNotes = new ObsResponses();
      }
      this.impression = new ObsResponses();
      this.conPreDate = new ObsResponses();
      this.conPreStartTime = new ObsResponses();
      this.conPreEndTime = new ObsResponses();
      this.conPostDate = new ObsResponses();
      this.conPostStartTime = new ObsResponses();
      this.conPostEndTime = new ObsResponses();
      this.contactInfo = new ObsResponses();
    }
  }

  private getTimeIn24HourFormat(timeIn12HourFormat: string): string {

    if (!timeIn12HourFormat || timeIn12HourFormat.length === 0) {
      return "";
    }

    // accommodate for timeIn12HourFormat string values containing the server response with '.' instead of ':' for times
    timeIn12HourFormat = timeIn12HourFormat.includes(".") ? timeIn12HourFormat.replace(".", ":") : timeIn12HourFormat;

    const splitTime = timeIn12HourFormat.split(" ");

    const timeValue = splitTime[0];
    const amPMValue = splitTime[1];

    const hoursAndMins = timeValue.split(":");

    let hoursPartValue = parseInt(hoursAndMins[0]);
    const minsPartValue = hoursAndMins[1];

    // start and end times can range between 07:00 AM to 6:30 PM
    if (amPMValue === "PM" && hoursPartValue < 12) {
      hoursPartValue += 12;
    }

    const timeIn24HourFormat = `${hoursPartValue < 10 ? '0'.concat(hoursPartValue.toString()) : hoursPartValue}:${minsPartValue}`;

    return timeIn24HourFormat;
  }

  SaveNewObs() {
    if (this.obsAssessment.ContactTypeId == ContactTypeEnum.InformalObservation)
      return this.SaveNewInformalObs();
    
    if (this.obsAssessment.ContactTypeId == ContactTypeEnum.Conference)
      return this.SaveNewConference();
    
    this.FillAssessmentData();
    this.ValidateFields();
    if (this.formValidForSave) {
      this.saveButtonClicked = true;
      this.observationService.SaveObsContact(this.obsAssessment).subscribe(data => {
        this.router.navigate(['/intern-details', this.internId]);
      }, (error) => {
        this.saveButtonClicked = false;
      });
    }

    this.formValidForSave = true;
    this.formValidForFinalize = true;
  }

  SaveNewInformalObs() {
    this.FillInformalObservationAssessmentData();
    this.ValidateInformalObservationFields();

    if (this.formValidForSave) {      
      this.observationService.SaveObsContact(this.informalObservationState.obsAssessment).subscribe({
        next: (result) => this.router.navigate(['/intern-details', this.internId]),
        error: (error) => {
          console.log("Error while saving the informal observation", error);
          this.saveButtonClicked = false;
        }
      });
    }

    this.formValidForSave = true;
    this.formValidForFinalize = true;
  }

  SaveNewConference() {
    this.FillConferenceAssessmentData();
    this.ValidateConferenceFieldsForSaving();

    if (this.formValidForSave) {      
      this.observationService.SaveObsContact(this.conferenceState.obsAssessment).subscribe({
        next: (result) => this.router.navigate(['/intern-details', this.internId]),
        error: (error) => {
          console.log("Error while saving the informal observation", error);
          this.saveButtonClicked = false;
        }
      });
    }

    this.formValidForSave = true;
    this.formValidForFinalize = true;
  }

  FinishNewObs() {
    if (this.obsAssessment.ContactTypeId == ContactTypeEnum.InformalObservation)
      return this.FinishNewInformalObs();

    if (this.obsAssessment.ContactTypeId == ContactTypeEnum.Conference)
      return this.FinishNewConference();

    this.FillAssessmentData();
    this.ValidateFieldsForFinalize();

    if (this.formValidForFinalize) {
      this.finishButtonClicked = true;
      this.observationService.FinishObsContact(this.obsAssessment).subscribe((data: boolean | string[]) => {

        if (Array.isArray(data) && data.length > 0) {
          const emailRecipients = [
            data.slice(0, -1)
              .join(', '),
            ...data.slice(-1)]
            .filter(x => x)
            .join(' and ');
          this.openSnackBar(`Completed Observation is queued to be emailed to ${emailRecipients}`, 'Okay')
        }

        //Determine if exists and alert to be sent
        if (this.obsAssessment.Alert.AlertTypeId) {

          this.sendAlertEmail().pipe(
            finalize(() => {
              console.log('Trying onFinalize', this.obsAssessment.Alert.AlertTypeId);
              //On Success or error determines if termination alert has to be send
              if (this.obsAssessment.Alert.AlertTypeId == ObsAlertTypeEnum.LeaveResignOrTerminate) {
                this.sendTerminationAlert();
              }
            })
          )
            .subscribe(result => {
              this.snackBar.open("Email sent successfully", 'Okay');
            },
              (error) => {
                this.openSnackBar('Email failed to send', 'Error');
              }
            );

          this.saveAlertEmailNote();
        }

        this.router.navigate(['/intern-details', this.internId]);
      }, (error) => {
        this.finishButtonClicked = false;
      });
    }

    this.formValidForFinalize = true;
    this.formValidForSave = true;
  }

  FinishNewInformalObs() {
    this.FillInformalObservationAssessmentData();
    this.ValidateInformalObservationFieldsForFinalize();

    if (this.formValidForFinalize) {
      this.finishButtonClicked = true;
      this.observationService.FinishObsContact(this.informalObservationState.obsAssessment).subscribe({
        next: (data: boolean | string[]) => {
            if (Array.isArray(data) && data.length > 0) {
              const emailRecipients = [
                data.slice(0, -1)
                  .join(', '),
                ...data.slice(-1)]
                .filter(x => x)
                .join(' and ');
              this.openSnackBar(`Completed Informal Observation is queued to be emailed to ${emailRecipients}`, 'Okay')
            }

            this.router.navigate(['/intern-details', this.internId]);
        },
        error: (error) => {
          console.log("Error while completing the informal observation", error);
          this.finishButtonClicked = false;
        }
      });
    }

    this.formValidForFinalize = true;
    this.formValidForSave = true;
  }

  FinishNewConference() {
    this.FillConferenceAssessmentData();
    this.ValidateConferenceFieldsForFinishing();

    if (this.formValidForFinalize) {
      this.finishButtonClicked = true;
      this.observationService.FinishObsContact(this.conferenceState.obsAssessment).subscribe({
        next: (data: boolean | string[]) => {
            if (Array.isArray(data) && data.length > 0) {
              const emailRecipients = [
                data.slice(0, -1)
                  .join(', '),
                ...data.slice(-1)]
                .filter(x => x)
                .join(' and ');
              this.openSnackBar(`Completed conference is queued to be emailed to ${emailRecipients}`, 'Okay')
            }

            this.router.navigate(['/intern-details', this.internId]);
        },
        error: (error) => {
          console.log("Error while completing the conference", error);
          this.finishButtonClicked = false;
        }
      });
    }

    this.formValidForFinalize = true;
    this.formValidForSave = true;
  }

  ValidateFields() {
    // these five fields are required to save
    if (this.obsAssessment.ContactTypeId != 1) return; // it's not an observation

    let validationFeedback: string[] = [];
    this.responseValidationFeedback = [];

    if (this.showAlertDropdown && this.obsAssessment.Alert.AlertTypeId && (!this.obsAssessment.Alert.Description || this.obsAssessment.Alert.Description.length <= 0)) {
      this.formValidForSave = false;
      this.formValidForFinalize = false;
      this.responseValidationFeedback.push('A description for alert is required');
      Helper.addCssClass("alert-description", "validation-error");
    }

    if (this.isTexasObs && !this.obsAssessment.observation?.Subject || this.obsAssessment.observation.Subject?.trim().length === 0) {
      this.formValidForSave = false;
      this.formValidForFinalize = false;
      this.responseValidationFeedback.push('A subject/grade-level is required');
      Helper.addCssClass("subject", "validation-error");
    }


    if (this.obsAssessment.observation.StartTime == "0" || this.obsAssessment.observation.StartTime == null) {
      this.formValidForSave = false;
      this.formValidForFinalize = false;
      this.responseValidationFeedback.push('A start time is required');
      Helper.addCssClass("start-time", "validation-error");
    }
    else {
      if (!this.isValidTime(this.getTimeIn24HourFormat(this.obsAssessment.observation.StartTime))) {
        console.log('start time', this.obsAssessment.observation.StartTime);
        this.formValidForSave = false;
        this.formValidForFinalize = false;
        this.responseValidationFeedback.push("Start time must be between 7:00 AM and 4:30 PM.");
        Helper.addCssClass("start-time", "validation-error");
      }
    }

    if (this.obsAssessment.observation.EndTime == "0" || this.obsAssessment.observation.EndTime == null) {
      this.formValidForSave = false;
      this.formValidForFinalize = false;
      this.responseValidationFeedback.push('An end time is required');
      Helper.addCssClass("end-time", "validation-error");
    }
    else {
      if (!this.isValidTime(this.getTimeIn24HourFormat(this.obsAssessment.observation.EndTime))) {
        this.formValidForSave = false;
        this.formValidForFinalize = false;
        this.responseValidationFeedback.push("End time must be between 7:00 AM and 4:30 PM.");
        Helper.addCssClass("end-time", "validation-error");
      }
    }

    const minObsDuration = this.isTexasObs ? 0.75 : 0.5;
    const minObsDurationMins = minObsDuration * 60;
    const isDurationValid = this.ObsDuration && parseFloat(this.ObsDuration.nativeElement.value.split(' ')[0]) >= minObsDuration;
    if (!isDurationValid) {
      this.formValidForSave = false;
      this.formValidForFinalize = false;
      this.responseValidationFeedback.push(`The observation duration needs to be at least ${minObsDurationMins} minutes long`);
      Helper.addCssClass("obs-duration", "validation-error");
    }

    if (this.isMileageFieldRequired()) {
      this.formValidForSave = false;
      this.formValidForFinalize = false;
      this.responseValidationFeedback.push('A visitation format selection is required');
      Helper.addCssClass("ObsVisitationFormat", "validation-error");
    }

    if(this.obsAssessment.observation.VisitationFormatId == VisitationFormatEnum.InPerson && this.isFullTimeFieldSupervisor) {
      if (this.obsAssessment.observation.MileageId == 0 || this.obsAssessment.observation.MileageId == null) {
        this.formValidForSave = false;
        this.formValidForFinalize = false;
        this.responseValidationFeedback.push('A mileage selection is required');
        Helper.addCssClass("ObsMileage", "validation-error");
      }
    }

    if (this.obsAssessment.Date == null || this.obsAssessment.Date == "") {
      this.formValidForSave = false;
      this.formValidForFinalize = false;
      this.responseValidationFeedback.push('An observation date is required');
      Helper.addCssClass("obsAssessment-date", "validation-error");
    } else if (new Date(this.obsAssessment.Date) > this.getDate()) {
      this.invalidateField("obsAssessment-date", 'Contact date cannot be in the future');
    } else {
      this.restrictWeekendsForObservations();
    }
    if (this.obsAssessment.ContactTypeId == 0 || this.obsAssessment.ContactTypeId == null) {
      this.formValidForSave = false;
      this.formValidForFinalize = false;
      this.responseValidationFeedback.push('A contact type is required');
      Helper.addCssClass("obsAssessment-contact-type", "validation-error");
    }
    if (this.obsAssessment.AssessmentId == 0 || this.obsAssessment.AssessmentId == null) {
      this.formValidForSave = false;
      this.formValidForFinalize = false;
      this.responseValidationFeedback.push('An observation number is required');
      Helper.addCssClass("observationDropdown", "validation-error");
    }

    if (this.obsAssessment.observation.VisitationFormatId != VisitationFormatEnum.InPerson && this.obsAssessment.observation.InPersonRestricted) {
      this.formValidForSave = false;
      this.formValidForFinalize = false;
      this.responseValidationFeedback.push(`${this.formalObservationNumberText} Formal Observation needs to be In Person.`);
      Helper.addCssClass("ObsVisitationFormat", "validation-error");
    }

    if (this.responseValidationFeedback.length > 0) {
      this.bottomSheet.open(ObservationFormErrorSheetComponent, {
        data: {
          title: 'Please provide the information listed below to save your observation.',
          messages: this.responseValidationFeedback
        }
      });
    }

    return validationFeedback;
  }

  ValidateConferenceFieldsForSaving() {
    this.ValidateConferenceBaseFields();

    if (this.responseValidationFeedback.length > 0) {
      this.formValidForSave = false;
      this.bottomSheet.open(ObservationFormErrorSheetComponent, {
        data: {
          title: 'Please provide the information listed below to save your Conference.',
          messages: this.responseValidationFeedback
        }
      });
    }
  }

  ValidateConferenceFieldsForFinishing() {
    Helper.removeCssClass("collaborators", "validation-error");
    Helper.removeCssClass("notes", "validation-error");
    Helper.removeCssClass("acknowledge-text", "validation-error-text");
    
    this.ValidateConferenceBaseFields();

    if (this.conferenceState.collaborators == null || this.conferenceState.collaborators.length === 0) {
      this.responseValidationFeedback.push('At least one collaborator is required.');
      Helper.addCssClass("collaborators", "validation-error");
    }

    if (this.conferenceState.conferenceNotes == null || this.conferenceState.conferenceNotes === "") {
      this.responseValidationFeedback.push('Notes from Conference are required.');
      Helper.addCssClass("notes", "validation-error");
    }

    if (this.conferenceState.signature == null || !this.conferenceState.signature) {
      this.responseValidationFeedback.push('Acknowledgement is required.');
      Helper.addCssClass("acknowledge-text", "validation-error-text");
    }

    if (this.conferenceState.collaborators?.includes(RecipientTypeEnum.CampusSupervisor) && (this.lstcert.Campus.CampusSupervisorName == null || this.lstcert.Campus.CampusSupervisorName === "")) {
      this.responseValidationFeedback.push('Campus Supervisor not assigned for Intern. Please request Advisor to add Campus Supervisor assignment for the Intern.');
    }

    if (this.responseValidationFeedback.length > 0) {
      this.formValidForFinalize = false;
      this.bottomSheet.open(ObservationFormErrorSheetComponent, {
        data: {
          title: 'Please provide the information listed below to finish your Conference.',
          messages: this.responseValidationFeedback
        }
      });
    }
  }

  ValidateConferenceBaseFields() {
    this.responseValidationFeedback = [];

    Helper.removeCssClass("start-time", "validation-error");
    Helper.removeCssClass("end-time", "validation-error");
    Helper.removeCssClass("visitation-option", "validation-error");
    Helper.removeCssClass("obsAssessment-date", "validation-error");

    if (this.conferenceState.obsAssessment.Date == null || this.conferenceState.obsAssessment.Date == "") {
      this.responseValidationFeedback.push('A contact date is required.')
      Helper.addCssClass("obsAssessment-date", "validation-error");
    } else if (new Date(this.conferenceState.obsAssessment.Date) > new Date()) {
      this.responseValidationFeedback.push('The contact date cannot be a future date.')
      Helper.addCssClass("obsAssessment-date", "validation-error");
    }

    if (this.conferenceState.obsAssessment.observation.StartTime == null || this.conferenceState.obsAssessment.observation.StartTime == "" || this.conferenceState.obsAssessment.observation.StartTime == "0") {
      this.responseValidationFeedback.push('A start time is required.')
      Helper.addCssClass("start-time", "validation-error");
    }

    if (this.conferenceState.obsAssessment.observation.EndTime == null || this.conferenceState.obsAssessment.observation.EndTime == "" || this.conferenceState.obsAssessment.observation.EndTime == "0") {
      this.responseValidationFeedback.push('An end time is required.')
      Helper.addCssClass("end-time", "validation-error");
    }

    if (this.conferenceState.obsAssessment.observation.VisitationFormatId == 0) {
      this.responseValidationFeedback.push('A visitation format selection is required.')
      Helper.addCssClass("visitation-option", "validation-error");
    }
  }

  ValidateInformalObservationFields() {
    this.responseValidationFeedback = [];

    Helper.removeCssClass("start-time", "validation-error");
    Helper.removeCssClass("end-time", "validation-error");
    Helper.removeCssClass("visitation-option", "validation-error");
    Helper.removeCssClass("obsAssessment-date", "validation-error");
    Helper.removeCssClass("duration-time","validation-error")

    if(this.obsAssessment.observation.StartTime == null || this.obsAssessment.observation.StartTime == "" || this.obsAssessment.observation.StartTime == "0"){
      this.responseValidationFeedback.push('A start time is required')
      Helper.addCssClass("start-time", "validation-error");
      this.formValidForSave = false;
    }

    if(this.obsAssessment.observation.EndTime == null || this.obsAssessment.observation.EndTime == ""||  this.obsAssessment.observation.EndTime == "0"){
      this.responseValidationFeedback.push('A end time is required')
      Helper.addCssClass("end-time", "validation-error");
      this.formValidForSave = false;
    }

    if(this.obsAssessment.observation.VisitationFormatId == 0 ){
      this.responseValidationFeedback.push('A Visitation Format is required')
      Helper.addCssClass("visitation-option", "validation-error");
      this.formValidForSave = false;
    }

    if(this.obsAssessment.Date == null || this.obsAssessment.Date == ""){
      this.responseValidationFeedback.push('A Contact Date is required')
      Helper.addCssClass("obsAssessment-date", "validation-error");
      this.formValidForSave = false;
    }

    if(this.obsAssessment.observation.ObservationNumberText === "First" && this.obsAssessment.observation.VisitationFormatId !== 1){
      this.InvalidateForm('First Informal observation needs to be in-person');
      Helper.addCssClass("visitation-option", "validation-error");
    }

    this.loadDuration();
    const DurationValid = this.duration >= this.minimumDuration;
    if (!DurationValid) {
      this.InvalidateForm(`Informal Observation should be at least ${this.minimumDuration} mins long`);
      Helper.addCssClass("duration-time","validation-error")
    }

    if (this.responseValidationFeedback.length > 0) {
      this.bottomSheet.open(ObservationFormErrorSheetComponent, {
        data: {
          title: 'Please provide the information listed below to save your observation.',
          messages: this.responseValidationFeedback
        }
      });
    }
  }

  ValidateInformalObservationFieldsForFinalize() {
    var validationTitle = "";
    this.responseValidationFeedback = [];

    const observation = this.obsAssessment.observation;
      Helper.removeCssClass("start-time", "validation-error");
      Helper.removeCssClass("end-time", "validation-error");
      Helper.removeCssClass("end-time", "validation-error");
      Helper.removeCssClass("discussion-textbox", "validation-error");
      Helper.removeCssClass("digital-sign-checkbox-text", "validation-error-text");
      Helper.removeCssClass("digital-sign-checkbox-text", "validation-error-text");
      Helper.removeCssClass("visitation-option", "validation-error");
      Helper.removeCssClass("duration-time","validation-error")
      Helper.removeCssClass("visitation-option", "validation-error");

      if (!observation.StartTime || observation.StartTime.trim() === ""|| observation.EndTime == "0") {
        this.InvalidateForm('A start time is required');
        Helper.addCssClass("start-time", "validation-error");
      }
      
      if (!observation.EndTime || observation.EndTime.trim() === "" || observation.EndTime == "0") {
        this.InvalidateForm('An end time is required');
        Helper.addCssClass("end-time", "validation-error");
      }
      
      if (!observation.VisitationFormatId) {
        this.InvalidateForm('A Visitation Format is required');
        Helper.addCssClass("visitation-option", "validation-error");
      }
      
      if (!this.informalObservationState.observationDiscussion || this.informalObservationState.observationDiscussion.trim() === "") {
        this.InvalidateForm('A Description for Meeting is required');
        Helper.addCssClass("discussion-textbox", "validation-error");
      }
      
      if (!this.informalObservationState.signature) {
        this.InvalidateForm('The agreement checkbox is required');
        Helper.addCssClass("digital-sign-checkbox-text", "validation-error-text");
      }

      if(observation.ObservationNumberText === "First" && observation.VisitationFormatId !== 1){
          this.InvalidateForm('First Informal observation needs to be in-person');
          Helper.addCssClass("visitation-option", "validation-error");
        }

        if(this.obsAssessment.Date == null ||this.obsAssessment.Date == ""){
          this.InvalidateForm('A Contact Date is required')
          Helper.addCssClass("obsAssessment-date", "validation-error");
          this.formValidForSave = false;
        }
      
      this.loadDuration();
      const DurationValid = this.duration >= this.minimumDuration;
      if (!DurationValid) {
        this.InvalidateForm(`Informal Observation should be at least ${this.minimumDuration} mins long`);
        Helper.addCssClass("duration-time","validation-error")
      }

    //Show description of missing form data
    if (this.responseValidationFeedback.length > 0) {
      this.formValidForFinalize = false;
      validationTitle = "Please provide the information listed below to finish your observation.";
      this.bottomSheet.open(ObservationFormErrorSheetComponent, {
        data: {
          title: validationTitle,
          messages: this.responseValidationFeedback
        }
      });
    }
  }

  getDate(offSetDays = 0) {
    const today = new Date();
    const offsetDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + offSetDays);

    return offsetDate;
  }

  removeValidationError(elementId: string) {
    Helper.removeCssClass(elementId, "validation-error");
  }

  private onSubject_Changed(): void {
    Helper.removeCssClass("subject", "validation-error");
  }

  private onMileage_Changed(): void {
    Helper.removeCssClass("ObsMileage", "validation-error");
  }

  onVisitationFormat_Changed(): void {
    const selectedVisitationFormatId = this.obsAssessment.observation.VisitationFormatId;
    const isInperson = selectedVisitationFormatId == VisitationFormatEnum.InPerson;
    const isMeetAndGreet = this.obsAssessment.ContactTypeId == ContactTypeEnum.MeetAndGreet;

    if (this.obsAssessment.observation.InPersonRestricted && !isInperson) {
      this.showInPersonError = true;
      this.formalObservationNumberText = this.formalObservationNumberText ?? this.obsAssessment.observation.ObservationNumberText;
      Helper.addCssClass("ObsVisitationFormat", "validation-error");
    } else {
      this.showInPersonError = false;
      Helper.removeCssClass("ObsVisitationFormat", "validation-error");
    }

    if ((isInperson || isMeetAndGreet) && this.isFullTimeFieldSupervisor) {
      this.showMileageField = true;
    }
    else {
      this.showMileageField = false;
    }
  }

  private onStartTime_Changed(): void {
    Helper.removeCssClass("start-time", "validation-error");

    const startTimeElement = (<HTMLInputElement>document.getElementById("start-time"));

    this.obsAssessment.observation.StartTime = startTimeElement.value;
    this.loadDuration();
  }

  private onEndTime_Changed(): void {

    Helper.removeCssClass("end-time", "validation-error");

    const endTimeElement = (<HTMLInputElement>document.getElementById("end-time"));

    this.obsAssessment.observation.EndTime = endTimeElement.value;
    this.loadDuration();
  }

  private onContactDate_Changed(): void {
    Helper.removeCssClass("obsAssessment-date", "validation-error");
  }

  private onContactType_Changed(): void {
    Helper.removeCssClass("obsAssessment-contact-type", "validation-error");
  }

  private onObservationNumber_Changed(): void {
    Helper.removeCssClass("observationDropdown", "validation-error");
    this.attachment = new AssessmentAttachment(this.obsAssessment.observation.AttachmentFilename, this.obsAssessment.AssessmentId);
  }

  private onAlertDescription_Changed(): void {
    Helper.removeCssClass("alert-description", "validation-error");
  }

  private onContactRecipient_Changed(): void {
    Helper.removeCssClass("contact-recipient", "validation-error");
  }

  private onDigitalSignCheckbox_Changed(): void {
    Helper.removeCssClass("digital-sign-checkbox-text", "validation-error-text");
    this.digitalSign  = this.DigitalSignCheckbox.nativeElement.checked;
  }

  private onContactInfoText_Changed(): void {
    Helper.removeCssClass("contact-information", "validation-error");
  }

  private onPreConferenceStartTime_Changed(): void {
    Helper.removeCssClass("pre-conference-start-time", "validation-error");
  }

  private onPreConferenceEndTime_Changed(): void {
    Helper.removeCssClass("pre-conference-end-time", "validation-error");
  }

  private onPostConferenceStartTime_Changed(): void {
    Helper.removeCssClass("post-conference-start-time", "validation-error");
  }

  private onPostConferenceEndTime_Changed(): void {
    Helper.removeCssClass("post-conference-end-time", "validation-error");
  }

  private onPreConferenceDate_Changed(): void {
    Helper.removeCssClass("pre-conference-date", "validation-error");
  }

  private onPostConferenceDate_Changed(): void {
    Helper.removeCssClass("post-conference-date", "validation-error");
    // remove error on start time as well
    Helper.removeCssClass("post-conference-start-time", "validation-error");
  }

  private onOverallAssessment_Changed(): void {
    Helper.removeCssClass("overall-assessment", "validation-error");
  }

  private onMeetAndGreetMileage_Changed(): void {
    Helper.removeCssClass("informal-observation-mileage", "validation-error");
  }

  private isValidTime(timeValue: string): boolean {
    if (!timeValue) {
      return false;
    }

    const hoursAndMins = timeValue.split(":");

    const hoursPart = parseInt(hoursAndMins[0]);
    const minsPart = parseInt(hoursAndMins[1]);

    if (hoursPart < 7 || hoursPart > 16 || (hoursPart == 16 && minsPart > 30)) {
      return false;
    }

    return true;
  }

   InvalidateForm(message) {
    this.formValidForSave = false;
    this.formValidForFinalize = false;
    this.responseValidationFeedback.push(message);
  }

  ValidateFieldsForFinalize() {
    var validationTitle = "";
    this.responseValidationFeedback = [];

    // it's not an observation or a three week initial contact
    if (this.obsAssessment.ContactTypeId != ContactTypeEnum.Observation && this.obsAssessment.ContactTypeId != ContactTypeEnum.ThreeWeekInitialContact) {
    
      // it's a meet and greet
      if (this.obsAssessment.ContactTypeId == ContactTypeEnum.MeetAndGreet && this.isFullTimeFieldSupervisor) {
        // mileage is required
        if (this.obsAssessment.observation.MileageId == 0 || this.obsAssessment.observation.MileageId == null) {
          this.formValidForSave = false;
          this.formValidForFinalize = false;
          this.responseValidationFeedback.push('A mileage selection is required');
          Helper.addCssClass("informal-observation-mileage", "validation-error");
        }
      }

      // contact date is required
      if (this.obsAssessment.Date == null || this.obsAssessment.Date == "") {
        this.formValidForSave = false;
        this.formValidForFinalize = false;
        //display contact date is required for non-observation contacts
        this.responseValidationFeedback.push('A contact date is required');
        Helper.addCssClass("obsAssessment-date", "validation-error");
      } else if (new Date(this.obsAssessment.Date) > this.getDate()) {
        this.invalidateField("obsAssessment-date", 'Contact date cannot be in the future');
      } else {
        this.restrictWeekendsForObservations();
      }

      // contact type is required
      if (this.obsAssessment.ContactTypeId == ContactTypeEnum.None || this.obsAssessment.ContactTypeId == null) {
        this.formValidForSave = false;
        this.formValidForFinalize = false;
        this.responseValidationFeedback.push('A contact type is required');
        Helper.addCssClass("obsAssessment-contact-type", "validation-error");
      }

      // alert is required
      if ((this.obsAssessment.ContactTypeId != 0 && this.obsAssessment.ContactTypeId != null) &&
           (this.obsAssessment.Alert.AlertTypeId == 0 || this.obsAssessment.Alert.AlertTypeId == null)) {
        this.formValidForSave = false;
        this.formValidForFinalize = false;
        this.responseValidationFeedback.push('An alert type is required');
        Helper.addCssClass("alert", "validation-error");
      }

      // alert description is required when an alert is selected
      if ((this.obsAssessment.Alert.AlertTypeId != 0 && this.obsAssessment.Alert.AlertTypeId != null) &&
        (!this.obsAssessment.Alert.Description || this.obsAssessment.Alert.Description.length <= 0)) {
        this.formValidForSave = false;
        this.formValidForFinalize = false;
        this.responseValidationFeedback.push('A description for alert is required');
        Helper.addCssClass("alert-description", "validation-error");
      }

      // contact recipient is required for non-observation type contacts
      if ((this.obsAssessment.ContactTypeId != ContactTypeEnum.None
        && this.obsAssessment.ContactTypeId != null
        && this.obsAssessment.ContactTypeId != ContactTypeEnum.Observation
        && this.obsAssessment.ContactTypeId != ContactTypeEnum.ThreeWeekInitialContact)
        && (this.obsAssessment.contactRecipientIds == null || this.obsAssessment.contactRecipientIds.length == 0)) {
        this.formValidForSave = false;
        this.formValidForFinalize = false;
        this.responseValidationFeedback.push('At least 1 contact recipient is required');
        Helper.addCssClass("contact-recipient", "validation-error");
      }

      validationTitle = "Please provide the information listed below to finish your contact.";
    }

    if (this.obsAssessment.ContactTypeId == ContactTypeEnum.Observation) {
      // all fields are required to finalize except for recommendations and pending requirements
      let saveValidationFeedback: string[] = this.ValidateFields(); // requirements for save are a subset of requirements for finalize.		
      this.responseValidationFeedback.concat(saveValidationFeedback);

      // Convert the dates to Date objects, if they're not already
      let preConferenceDate = new Date(this.conPreDate.ResponseValue);
      let contactDate = new Date(this.obsAssessment.Date);
      let postConferenceDate = new Date(this.conPostDate.ResponseValue);

      // Ensure that pre-conference date is not on or after the contact date
      if (preConferenceDate && contactDate &&  preConferenceDate >= contactDate) {
        this.responseValidationFeedback.push('Pre-Conference Date has to be before the Contact Date.');
        Helper.addCssClass("pre-conference-date", "validation-error");
      }

      // Ensure that pre-conference date is not in the future
      if (preConferenceDate && preConferenceDate > this.getDate()) {
        this.responseValidationFeedback.push('Pre-Conference Date cannot be in the future.');
        Helper.addCssClass("pre-conference-date", "validation-error");
      }

      // Ensure that post-conference date is on or after the contact date
      if (postConferenceDate && postConferenceDate < contactDate) {
        this.responseValidationFeedback.push('Post-Conference Date has to be on or after the Contact Date.');
        Helper.addCssClass("post-conference-date", "validation-error");
      }

      // Ensure that post-conference date is not in the future
      if (postConferenceDate && postConferenceDate > this.getDate()) {
        this.responseValidationFeedback.push('Post-Conference Date cannot be in the future.');
        Helper.addCssClass("post-conference-date", "validation-error");
      }

      // Ensure that post-conference start time is greater than contact start time if both are on the same date
      // Ensure postConferenceStartTime is converted to 24-hour format before validation
      let postConferenceStartTime = this.convertTo24HourFormat(this.conPostStartTime.ResponseValue);
      let contactStartTime = this.convertTo24HourFormat(this.obsAssessment.observation.StartTime);

      // Create Date objects by giving the same date part to both dates so times can be compared
      let postConferenceStartDate = new Date(`01/01/2020 ${postConferenceStartTime}`);
      let contactStartDate = new Date(`01/01/2020 ${contactStartTime}`);

      // Assign midnight time to both dates so as to ignore time part when comparing if both the dates are the same
      let postConferenceDateWithMidnightTime = new Date(postConferenceDate.getFullYear(), postConferenceDate.getMonth(), postConferenceDate.getDate());
      let contactDateWithMidnightTime = new Date(contactDate.getFullYear(), contactDate.getMonth(), contactDate.getDate());

      if (postConferenceDateWithMidnightTime && postConferenceDateWithMidnightTime.getTime() === contactDateWithMidnightTime.getTime() 
          && postConferenceStartDate <= contactStartDate) { 
        this.responseValidationFeedback.push('Post-Conference Start Time has to be after the Contact Start Time.');
        Helper.addCssClass("post-conference-start-time", "validation-error");
      }

      this.mapElementNameToQuestionId(this.isTexasObs);

      if (this.isTexasObs) {
        this.ValidateResponsesForTexas();
      }
      else {
        if (!this.attachmentFilename) {
          let message = 'Observation document is missing.';
          this.responseValidationFeedback.push(message);
          let currentAttachment = this.attachment;
          if (currentAttachment) {
            currentAttachment.validationError = true;
            this.attachment = currentAttachment;
          }

          this.formValidForFinalize = false;
        }
        this.ValidateResponsesForExpansionStates();
      }

      if (!this.DigitalSignCheckbox.nativeElement.checked) {
        this.formValidForFinalize = false;
        this.formValidForSave = false;
        this.formValidForFinalize = false;
        this.responseValidationFeedback.push('The agreement checkbox is required');
        Helper.addCssClass("digital-sign-checkbox-text", "validation-error-text");
      }

      if (this.obsAssessment.AssessmentTypeId == 0 || this.obsAssessment.AssessmentTypeId == null) { // overall assessment
        this.formValidForFinalize = false;

        this.responseValidationFeedback.push('An overall assessment is required');
        Helper.addCssClass("overall-assessment", "validation-error");
      }

      validationTitle = "Please provide the information listed below to finish your observation.";

    }

    //Show description of missing form data
    if (this.responseValidationFeedback.length > 0) {
      this.formValidForFinalize = false;

      this.bottomSheet.open(ObservationFormErrorSheetComponent, {
        data: {
          title: validationTitle,
          messages: this.responseValidationFeedback
        }
      });
    }
  }

  private ValidateResponsesForTexas() {
    this.obsAssessment.observation.Responses.forEach((element) => {
      if (element.ControlType == "Ranking" && element.ResponseValue == "") { //proficiency rating
        const dimensionName = this.idToElementNameMap[element.QuestionId];
        const message = dimensionName + ' is missing a proficiency rating.'
        this.responseValidationFeedback.push(message);
        this.formValidForFinalize = false;
        Helper.addCssClass(dimensionName.toLowerCase().replace(/\s/g, "-").concat("-proficiency"), "validation-error");
      }
      else if ((element.QuestionId > 50) && element.ResponseValue == "") { // summary, conference dates and times and contact 

        if (!this.hasOverallImpression && element.QuestionId == ObsQuestionEnum.OverallImpression) {
          //do nothing
        }
        else {
          this.formValidForFinalize = false;
          let elementName = this.idToElementNameMap[element.QuestionId];
          if (elementName) {
            let message = elementName + ' is missing a value';
            this.responseValidationFeedback.push(message);
            Helper.addCssClass(elementName.toLowerCase().replace(/\s/g, "-"), "validation-error");
          }
          else {
            let message = element.QuestionId + ' is missing a value';
            this.responseValidationFeedback.push(message);
          }
        }
      }
      else if (this.evidenceIdToQuestionId[element.QuestionId] && element.ResponseValue == "") {

        const rankingQuestionId = this.evidenceIdToQuestionId[element.QuestionId];
        const dimensionName = this.idToElementNameMap[rankingQuestionId];
        const rankingQuestion = this.obsAssessment.observation.Responses.find(r => r.QuestionId === rankingQuestionId)
        if (dimensionName && rankingQuestion && rankingQuestion.ResponseValue != "0") {
          const message = dimensionName + ' is missing the evidence content.'
          this.responseValidationFeedback.push(message);
          this.formValidForFinalize = false;
          Helper.addCssClass(dimensionName.toLowerCase().replace(/\s/g, "-").concat("-evidence"), "validation-error");
        }
      }
    });
  }

  private ValidateResponsesForExpansionStates() {
    this.obsAssessment.observation.Responses.forEach(element => {
      if ((element.QuestionId >= 2) && element.ResponseValue == "") { // dates, times and contact info
        this.formValidForFinalize = false;
        let elementName = this.idToElementNameMap[element.QuestionId];
        if (elementName) {
          let message = elementName + ' is missing a value';
          this.responseValidationFeedback.push(message);
          Helper.addCssClass(elementName.toLowerCase().replace(/\s/g, "-"), "validation-error");
        }
        else {
          let message = element.QuestionId + ' is missing a value';
          this.responseValidationFeedback.push(message);
        }
      }
    });
  }

  FillAssessmentData() {
    this.fillBasicAssesmentInformation();
    this.obsAssessment.CampusId = this.lstcert.Campus.CampusId;

    //Check if three week initial contact
    if (this.ContactType.nativeElement.value == '5') {
      //Assign Observation Identifier to the Assessment

      if (this.obsAssessment.AssessmentId == 0) {
        let threeWeekInitialContact = this.lstcert.FsContacts.filter(contact => contact.ObsType == "Three Week Initial Contact");
        this.obsAssessment.AssessmentId = threeWeekInitialContact[0].AssessmentId;
      }
    }

    //Check if this is an observation
    if (this.ContactType.nativeElement.value == '1') {
      //Assign Observation Identifier to the Assessment
      // We shouldn't let them change which observation this is
      if (this.obsAssessment.AssessmentId == 0) {
        this.obsAssessment.AssessmentId = +this.ObsNumber.nativeElement.value;
      }

      this.obsAssessment.observation.MileageId = this.obsAssessment.observation.MileageId == 0 ? null : this.obsAssessment.observation.MileageId;

      //Get Campus and district from controls
      if (this.lstcert != null && this.lstcert.Campus != null) {
        this.obsAssessment.observation.CampusId = this.lstcert.Campus.CampusId;
        this.obsAssessment.observation.DistrictId = this.lstcert.Campus.DistrictId;
      }

      this.obsAssessment.InternFullName = this.lstcert.Intern.FirstName + ' ' + this.lstcert.Intern.LastName;
      this.obsAssessment.observation.Responses = [];

      if (this.isTexasObs) {
        this.PlanningResponses.forEach(element => {
          this.obsAssessment.observation.Responses.push(element);
        });

        this.InstructionResponses.forEach(element => {
          this.obsAssessment.observation.Responses.push(element);
        });

        this.LearningResponses.forEach(element => {
          this.obsAssessment.observation.Responses.push(element);
        });

        this.ProfessionalResponses.forEach(element => {
          this.obsAssessment.observation.Responses.push(element);
        });
      }

      if (this.hasOverallImpression) {
        this.impression.QuestionId = this.isTexasObs ? ObsQuestionEnum.OverallImpression : ObsQuestionGrowthStateEnum.OverallImpression;
        this.impression.ControlType = "FreeTextForm";
        this.obsAssessment.observation.Responses.push(this.impression);
      }
      else {
        this.totPendingReq.QuestionId = this.isTexasObs ? ObsQuestionEnum.TeachersOfTomorrowPendingRequirements : ObsQuestionGrowthStateEnum.TeachersOfTomorrowPendingRequirements;
        this.totPendingReq.ControlType = "FreeTextForm";
        this.obsAssessment.observation.Responses.push(this.totPendingReq);

        this.strengths.QuestionId = this.isTexasObs ? ObsQuestionEnum.Strengths : ObsQuestionGrowthStateEnum.Strengths;
        this.strengths.ControlType = "FreeTextForm";
        this.obsAssessment.observation.Responses.push(this.strengths);

        this.areasOfGrowth.QuestionId = this.isTexasObs ? ObsQuestionEnum.AreasOfGrowth : ObsQuestionGrowthStateEnum.AreasOfGrowth;
        this.areasOfGrowth.ControlType = "FreeTextForm";
        this.obsAssessment.observation.Responses.push(this.areasOfGrowth);

        this.actionableSteps.QuestionId = this.isTexasObs ? ObsQuestionEnum.ActionableSteps : ObsQuestionGrowthStateEnum.ActionableSteps;
        this.actionableSteps.ControlType = "FreeTextForm";
        this.obsAssessment.observation.Responses.push(this.actionableSteps);

        this.otherNotes.QuestionId = this.isTexasObs ? ObsQuestionEnum.OtherNotes : ObsQuestionGrowthStateEnum.OtherNotes;
        this.otherNotes.ControlType = "FreeTextForm";
        this.obsAssessment.observation.Responses.push(this.otherNotes);
      }

      this.conPreDate.QuestionId = this.isTexasObs ? ObsQuestionEnum.PreConferenceDate : ObsQuestionGrowthStateEnum.PreConferenceDate;
      this.conPreDate.ControlType = "Date";
      this.obsAssessment.observation.Responses.push(this.conPreDate);

      this.conPreStartTime.QuestionId = this.isTexasObs ? ObsQuestionEnum.PreStartTime : ObsQuestionGrowthStateEnum.PreStartTime;
      this.conPreStartTime.ControlType = "FreeTextForm";
      this.obsAssessment.observation.Responses.push(this.conPreStartTime);

      this.conPreEndTime.QuestionId = this.isTexasObs ? ObsQuestionEnum.PreEndTime : ObsQuestionGrowthStateEnum.PreEndTime;
      this.conPreEndTime.ControlType = "FreeTextForm";
      this.obsAssessment.observation.Responses.push(this.conPreEndTime);

      this.conPostDate.QuestionId = this.isTexasObs ? ObsQuestionEnum.PostConferenceDate : ObsQuestionGrowthStateEnum.PostConferenceDate;
      this.conPostDate.ControlType = "Date";
      this.obsAssessment.observation.Responses.push(this.conPostDate);

      this.conPostStartTime.QuestionId = this.isTexasObs ? ObsQuestionEnum.PostStartTime : ObsQuestionGrowthStateEnum.PostStartTime;
      this.conPostStartTime.ControlType = "FreeTextForm";
      this.obsAssessment.observation.Responses.push(this.conPostStartTime);

      this.conPostEndTime.QuestionId = this.isTexasObs ? ObsQuestionEnum.PostEndTime : ObsQuestionGrowthStateEnum.PostEndTime;
      this.conPostEndTime.ControlType = "FreeTextForm";
      this.obsAssessment.observation.Responses.push(this.conPostEndTime);

      this.contactInfo.QuestionId = this.isTexasObs ? ObsQuestionEnum.Contact : ObsQuestionGrowthStateEnum.Contact;
      this.contactInfo.ControlType = "FreeTextForm";
      this.obsAssessment.observation.Responses.push(this.contactInfo);    
    }
  }

  private fillBasicAssesmentInformation(){
    //Map CertProgramId this is mandatory getting certProgram active
    if (this.lstcert.Certificates != null && this.lstcert.Certificates.length > 0) {
      this.obsAssessment.CertProgramId = this.lstcert.Certificates.find(x => x.IsActive).CertProgramID;
    }

    //Map Profile Id
    this.obsAssessment.ProfileIdentifier = this.lstcert.Intern.profileId;
    //Map Supervisor Name to Assessment
    this.obsAssessment.Author = this.fieldSupervisor.displayName;
    //Map Author email
    this.obsAssessment.AuthorEmail = this.authService.user.email;
    //Map Intern name
    this.obsAssessment.InternFullName = this.lstcert.Intern.FirstName + ' ' + this.lstcert.Intern.LastName;
    // Map Coach Id
    this.obsAssessment.CoachId = this.fieldSupervisor.FieldSupervisorId;
    //Map Coach Email
    this.obsAssessment.CreateUser = this.fieldSupervisor.PrimaryEmail;
  }

  FillInformalObservationAssessmentData() {
    this.fillBasicAssesmentInformation();

    //Get Campus and district from controls
    if (this.lstcert != null && this.lstcert.Campus != null) {
      this.obsAssessment.CampusId = this.lstcert.Campus.CampusId;
      this.obsAssessment.observation.CampusId = this.lstcert.Campus.CampusId;
      this.obsAssessment.observation.DistrictId = this.lstcert.Campus.DistrictId;
    }

    this.obsAssessment.InternFullName = this.lstcert.Intern.FirstName + ' ' + this.lstcert.Intern.LastName;
    this.obsAssessment.observation.Responses = [ new ObsResponses() ];

    this.obsAssessment.observation.Responses[0].QuestionId = ObsQuestionEnum.DiscussionFromMeeting;
    this.obsAssessment.observation.Responses[0].ResponseValue = this.informalObservationState.observationDiscussion;
    this.obsAssessment.observation.Responses[0].ControlType = 'FreeTextForm'; 
  }

  FillConferenceAssessmentData() {
    this.fillBasicAssesmentInformation();

    //Get Campus and district from controls
    if (this.lstcert != null && this.lstcert.Campus != null) {
      this.obsAssessment.CampusId = this.lstcert.Campus.CampusId;
      this.obsAssessment.observation.CampusId = this.lstcert.Campus.CampusId;
      this.obsAssessment.observation.DistrictId = this.lstcert.Campus.DistrictId;
    }

    this.obsAssessment.InternFullName = this.lstcert.Intern.FirstName + ' ' + this.lstcert.Intern.LastName;
    this.obsAssessment.observation.Responses = [ new ObsResponses() ];

    this.obsAssessment.observation.Responses[0].QuestionId = ObsQuestionEnum.DiscussionFromMeeting;
    this.obsAssessment.observation.Responses[0].ResponseValue = this.conferenceState.conferenceNotes;
    this.obsAssessment.observation.Responses[0].ControlType = 'FreeTextForm'; 

    this.obsAssessment.contactRecipientIds = this.conferenceState.collaborators;
    this.obsAssessment.otherRecipientDetail = this.conferenceState.otherCollaboratorDetail;
  }

  PlanningDataChange(planData: any) {
    this.PlanningResponses = planData;
  }

  InstructionDataChange(planData: any) {
    this.InstructionResponses = planData;
  }

  LearningDataChange(planData: any) {
    this.LearningResponses = planData;
  }

  ProfessionalDataChange(planData: any) {
    if (planData != null) {
      this.ProfessionalResponses = planData;
    }
  }

  LoadObservations() {

    let threeWeekInitialContactCompleted = false;
    let threeWeekInitialContactScheduled = false;
    let informalObservationScheduled = false;
    let conferencesScheduled = false;
    let meetAndGreetCompleted = false;
    let observationsCompleted = false;
    let observationsScheduled = false;

    if (this.lstcert.FsContacts.length > 0) {
      threeWeekInitialContactScheduled = this.lstcert.FsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.ThreeWeekInitialContact).length > 0;
      threeWeekInitialContactCompleted = this.lstcert.FsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.ThreeWeekInitialContact)[0]?.ObsCompleted;  
      informalObservationScheduled= this.lstcert.FsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.InformalObservation).length > 0;
      conferencesScheduled = this.lstcert.FsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.Conference).length > 0;
      meetAndGreetCompleted = this.lstcert.FsContacts.some(contact => contact.ObsTypeId === ContactTypeEnum.MeetAndGreet);
      observationsScheduled = this.lstcert.FsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.Observation).length > 0;
      observationsCompleted = this.lstcert.FsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.Observation && !contact.ObsCompleted).length == 0;   
      this.blockObservationCreation = !this.assessmentId && this.lstcert.FsContacts.some(contact => contact.ObsTypeId === ContactTypeEnum.Observation && contact.ObsStatus == "Saved");
      this.blockInformalObservationCreation = this.lstcert.FsContacts.some(contact => contact.ObsTypeId === ContactTypeEnum.InformalObservation && contact.ObsStatus == "Saved");
      this.blockConferenceCreation = this.lstcert.FsContacts.some(contact => contact.ObsTypeId === ContactTypeEnum.Conference && contact.ObsStatus == "Saved");      
      observationsScheduled = true;
    }

    this.lstcert.Certificates.forEach(element => {
      element.Observations.forEach(ele => {
        if (!(ele.IsCompleted || ele.IsSaved) || this.assessmentId) {
          this.lstObservations.push(ele);
        }
      });
    });

    // initialize contacts type dropdown
    this.fsContactsService.GetContactTypes().subscribe(data => {

      this.contactTypeList = data;

      if (observationsCompleted || !observationsScheduled) {
        this.contactTypeList = this.contactTypeList.filter(contactType => contactType.id !== ContactTypeEnum.Observation);
      }

      if (threeWeekInitialContactCompleted || !threeWeekInitialContactScheduled) {
        this.contactTypeList = this.contactTypeList.filter(contactType => contactType.id !== ContactTypeEnum.ThreeWeekInitialContact);
      }

      if (!this.isTexasObs || meetAndGreetCompleted) {
        this.contactTypeList = this.contactTypeList.filter(contactType => contactType.id !== ContactTypeEnum.MeetAndGreet);
      }

      if (!informalObservationScheduled) {
        this.contactTypeList = this.contactTypeList.filter(contactType => contactType.id !== ContactTypeEnum.InformalObservation);
      }

      if (!conferencesScheduled) {
        this.contactTypeList = this.contactTypeList.filter(contactType => contactType.id !== ContactTypeEnum.Conference);
      }
    })

  }

  loadContactTypeChange(curEvt) {
    const messageText = this.getMessageInfoForSaveBlocked(curEvt.currentTarget.value);

    if(this.disableSaveButton)
    {
      this.newObservation = false;
      this.showObservation = false;
      this.showContactRecipientTypesDropdown = false;
      this.showMileageDropdownForMeetAndGreet = false;
      this.showAlertDropdown = false;
      this.bottomSheet.open(ObservationFormErrorSheetComponent, {
        data: {
          title: messageText.messageTitle,
          messages: [ messageText.messageText]
        }
      });
      return;
    }

    Helper.removeCssClass("obsAssessment-contact-type", "validation-error");
    Helper.removeCssClass("obsAssessment-date", "validation-error");

    this.loadSpinner = false;
    this.showObservation = false;
    this.showAlertDropdown = true;
    this.showInformalObservation = false;
    this.showConference = false;
    this.obsAssessment.AssessmentId = 0;

    if (curEvt.currentTarget.value == ContactTypeEnum.InformalObservation) {
      // set legend text
      this.legendText = 'informal observation';
      this.legendBeginningText = 'Informal observation';

      this.showAlertDropdown = false;
      let earliestIncompleteInformalObsContact = this.lstcert.FsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.InformalObservation && contact.ObsStatusId == ObsStatusEnum.Scheduled)[0];

      this.loadSpinner = true;
      this.observationService.GetAssessment(earliestIncompleteInformalObsContact.AssessmentId.toString()).subscribe(data => {
        this.loadInformalObservationState(data);
      });
    }

    if (curEvt.currentTarget.value == ContactTypeEnum.Observation) {
      // set legend text
      this.legendText = 'observation';
      this.legendBeginningText = 'Observation';
      
      this.newObservation = true;
      this.showObservation = true;
      this.showAlertDropdown = false;
      this.obsAssessment.AssessmentId = this.lstObservations[0]?.AssessmentId;

      // Gets the right assesment Id
      let earliestIncompleteFormalObsContact = this.lstcert.FsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.Observation && contact.ObsStatusId == ObsStatusEnum.Scheduled)[0];

      this.loadSpinner = true;
      this.observationService.GetAssessment(earliestIncompleteFormalObsContact.AssessmentId.toString()).subscribe(data => {
          // Retrieves correct assessment from database and populates not saved properties values
          this.obsAssessment = data;
          this.setDefaultNewObservationProperties(ContactTypeEnum.Observation);

          // This is a new observation, so we need to set the InPersonRestricted property
          this.setInPersonRestrictedProperty();

          this.newObservation = true;
          this.showObservation = true;
          this.onObservationNumber_Changed();
          this.loadSpinner = false;
      });
    }

    if (curEvt.currentTarget.value == ContactTypeEnum.Conference) {
      // set legend text
      this.legendText = 'conference';
      this.legendBeginningText = 'Conference';
      
      this.showAlertDropdown = false;
      
      let earliestIncompleteConferenceContact = this.lstcert.FsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.Conference && contact.ObsStatusId == ObsStatusEnum.Scheduled)[0];

      this.loadSpinner = true;
      this.observationService.GetAssessment(earliestIncompleteConferenceContact.AssessmentId.toString()).subscribe(assessment => {
        this.loadConferenceState(assessment);
      });
    }

    // only if a contact type other than Observation or Three Week Initial Contact is selected, show the dropdown to select Contact Recipient
    if (curEvt.currentTarget.value != ContactTypeEnum.None && 
        curEvt.currentTarget.value != ContactTypeEnum.Observation && 
        curEvt.currentTarget.value != ContactTypeEnum.ThreeWeekInitialContact && 
        curEvt.currentTarget.value != ContactTypeEnum.InformalObservation &&
        curEvt.currentTarget.value != ContactTypeEnum.Conference) {
          this.showContactRecipientTypesDropdown = true;
    }
    else {
      this.showContactRecipientTypesDropdown = false;
    }

    // only if a contact type of Meet and Greet is selected, show the Mileage dropdown    
    this.showMileageDropdownForMeetAndGreet = curEvt.currentTarget.value == ContactTypeEnum.MeetAndGreet && this.isFullTimeFieldSupervisor;
  }

  private getMessageInfoForSaveBlocked(selectedContactType: number) {    
    if (selectedContactType == ContactTypeEnum.Observation && this.blockObservationCreation) {
      this.disableSaveButton = true;
      return {
        messageTitle: 'Previous Observation in progress.', 
        messageText: 'There is an Observation in progress. Please submit it before beginning a new Observation.'
      };
    }

    if (selectedContactType == ContactTypeEnum.InformalObservation && this.blockInformalObservationCreation) {
      this.disableSaveButton = true;
      return {
        messageTitle: 'Previous Informal Observation in progress.', 
        messageText: 'There is an informal observation in progress. Please submit it before beginning a new Informal Observation.'
      };
    }

    if (selectedContactType == ContactTypeEnum.Conference && this.blockConferenceCreation) {
      this.disableSaveButton = true;
      return {
        blockSave: true,
        messageTitle: 'Previous Conference in progress.', 
        messageText: 'There is a conference in progress. Please submit it before beginning a new Conference.'
      };
    }

    this.disableSaveButton = false;
    return {
      messageTitle: '', 
      messageText: ''
    };
  }

  private loadInformalObservationState(assesment?: Assessment) {
    if(assesment) {
      this.obsAssessment = assesment;
      this.setDefaultNewObservationProperties(ContactTypeEnum.InformalObservation);
    }
    else {
      this.formalObservationNumberText = this.obsAssessment.observation.ObservationNumberText;
    }

    this.informalObservationState = new InformalObservationState();
    this.informalObservationState.obsAssessment = this.obsAssessment;
    this.informalObservationState.lstObservations = this.lstObservations;
    this.informalObservationState.lstcert = this.lstcert;
    this.informalObservationState.loadDuration = this.loadDuration;
    this.informalObservationState.getTimeIn24HourFormat = this.getTimeIn24HourFormat;
    this.informalObservationState.fieldSupervisorDisplayName = this.fieldSupervisor.displayName;
    this.informalObservationState.visitationFormatOptions = this.visitationFormatOptions;
    this.informalObservationState.observationDiscussion = this.obsAssessment!.observation!.Responses!.length > 0 ? this.obsAssessment!.observation!.Responses[0].ResponseValue : '';
    this.informalObservationState.signature = this.completed == 'true';
    this.showInformalObservation = true;
    this.loadSpinner = false;
  }

  private loadConferenceState(assesment?: Assessment) {
    if(assesment) {
      this.obsAssessment = assesment;
      this.setDefaultNewConferenceProperties();
    }

    this.conferenceState = new ConferenceState();
    this.conferenceState.obsAssessment = this.obsAssessment;
    this.conferenceState.lstObservations = this.lstObservations;
    this.conferenceState.lstCert = this.lstcert;
    this.conferenceState.loadDuration = this.loadDuration;
    this.conferenceState.getTimeIn24HourFormat = this.getTimeIn24HourFormat;
    this.conferenceState.fieldSupervisorDisplayName = this.fieldSupervisor.displayName;
    this.conferenceState.visitationFormatOptions = this.visitationFormatOptions;
    this.showConference = true;
    this.loadSpinner = false;
    this.conferenceState.conferenceNotes = this.obsAssessment!.observation!.Responses!.length > 0 ? this.obsAssessment!.observation!.Responses[0].ResponseValue : '';
    this.conferenceState.signature = this.completed == 'true';

    if(this.obsAssessment.contactRecipientIds && this.obsAssessment.contactRecipientIds.length > 0) {
      this.conferenceState.collaborators = this.obsAssessment.contactRecipientIds;

      if(this.obsAssessment.contactRecipientIds.some(id => id == RecipientTypeEnum.Other)) {
        this.conferenceState.otherCollaboratorDetail = this.obsAssessment.otherRecipientDetail;
      }
    }
  }

  private setDefaultNewObservationProperties(contactType: ContactTypeEnum) {
    this.obsAssessment.ContactTypeId = contactType;
    this.obsAssessment.Date = formatDate(new Date().toString(), 'yyyy-MM-dd', 'en');
    this.obsAssessment.Alert = new contactAlert();
    this.obsAssessment.StartTime = "0";
    this.obsAssessment.EndTime = "0";
    this.obsAssessment.observation.StartTime = "0";
    this.obsAssessment.observation.EndTime = "0";
    this.obsAssessment.observation.VisitationFormatId = VisitationFormatEnum.NotSelected;
    this.formalObservationNumberText = this.formalObservationNumberText ?? this.obsAssessment.observation.ObservationNumberText;
  }

  private setDefaultNewConferenceProperties() {
    this.obsAssessment.ContactTypeId = ContactTypeEnum.Conference;
    this.obsAssessment.Date = formatDate(new Date().toString(), 'yyyy-MM-dd', 'en');
    this.obsAssessment.StartTime = "0";
    this.obsAssessment.EndTime = "0";
    this.obsAssessment.observation.StartTime = "0";
    this.obsAssessment.observation.EndTime = "0";
    this.obsAssessment.observation.VisitationFormatId = VisitationFormatEnum.NotSelected;
  }

  private setInPersonRestrictedProperty() {
    if (this.obsAssessment.observation.InPersonRestricted == undefined) {
      this.lstcert.Certificates.forEach(cert => {
        cert.Observations.forEach(obs => {
          if (obs.AssessmentId == this.obsAssessment.AssessmentId) {
            this.obsAssessment.observation.InPersonRestricted = obs.InPersonRestricted;
            this.formalObservationNumberText = obs.Description.split(' ')[0];
          }
        });
      });
    }
  }

  loadContactType(value) {
    this.showObservation = value == ContactTypeEnum.Observation;
    this.showInformalObservation = value == ContactTypeEnum.InformalObservation;
    this.showConference = value == ContactTypeEnum.Conference;
  }

  loadAlertChange(curEvt) {
    Helper.removeCssClass("alert", "validation-error");
    this.showAlertExp = true;
    if (curEvt.currentTarget.value == 0) {
      this.showAlertExp = false;
    }
  }

  loadPreConferenceStartTimeDropdown() {
    let isNoon = false;
    let starttime = 7.00;
    this.preConferenceStartTimes.push('7.00 AM');

    for (let index = 0; index < 59; index++) {
      starttime = starttime + 0.15
      if (starttime.toFixed(2).toString().indexOf('.60') != -1) {
        starttime = Math.round(starttime);
      }
      if (starttime == 12.00) {
        isNoon = true;
      }
      if (starttime == 13.00) {
        starttime = 1.00;
      }
      if (isNoon) {
        this.preConferenceStartTimes.push(starttime.toFixed(2).toString() + ' PM');
      }
      else {
        this.preConferenceStartTimes.push(starttime.toFixed(2).toString() + ' AM');
      }
    }
  }

  loadPreConferenceEndTimeDropdown(event) {
    let isNoon = false;
    let isAfternoon = false;

    this.preConferenceEndTimes = [];
    let preConfStartTime = this.conPreStartTime.ResponseValue;

    if (preConfStartTime.indexOf('PM') != -1) {
      if (parseFloat(preConfStartTime.substring(0, 3)) == 12) {
        isNoon = true;
      }
      else {
        isAfternoon = true;
      }
    }

    let starttime = parseFloat(preConfStartTime.substring(0, preConfStartTime.indexOf(' ')));
    this.preConferenceEndTimes.push(preConfStartTime);

    for (let index = 0; index <= 59; index++) {
      starttime = starttime + 0.15
      if (starttime.toFixed(2).toString().indexOf('.60') != -1) {
        starttime = Math.round(starttime);
      }
      if (starttime == 12.00) {
        isNoon = true;
      }
      if (starttime == 13.00) {
        isAfternoon = true;
        starttime = 1.00;
      }

      if (isNoon || isAfternoon) {
        if (isAfternoon && starttime > 10.00) {
          break;
        }
        this.preConferenceEndTimes.push(starttime.toFixed(2).toString() + ' PM');
      }
      else {
        this.preConferenceEndTimes.push(starttime.toFixed(2).toString() + ' AM');
      }
    }
  }

  loadDuration() {

    /***
     * This method is being called on
     * ngAfterViewInit()
     * startTime_Changed
     * endTime_Changed
     *
     * Start and End times from the server come back in the following format: "HH.MM AM or HH.MM PM" with a "dot" notation.
     * The start and end times are being converted to the correct time format after receiving the response from the server.
     */

    // return if we do not have valid start and end times
    const validObservationTimesAvailable = (
      this.obsAssessment
      && this.obsAssessment.observation
      && this.obsAssessment.observation.StartTime
      && this.obsAssessment.observation.EndTime
      && this.obsAssessment.observation.StartTime !== "0"
      && this.obsAssessment.observation.EndTime !== "0"
    );

    if (!validObservationTimesAvailable && this.ObsDuration) {
      this.ObsDuration.nativeElement.value = "";
      return;
    }

    let tstart = this.obsAssessment.observation.StartTime;
    let tend = this.obsAssessment.observation.EndTime;

    const startHoursAndMins = this.getTimeIn24HourFormat(tstart).split(":");
    const endHoursAndMins = this.getTimeIn24HourFormat(tend).split(":");

    // set the start and end dates to the ECMAScript epoch, equivalent to UNIX epoch to make sure both start and end times are for the same date
    let startDate = new Date('1970-01-01T00:00:00');
    let endDate = new Date('1970-01-01T00:00:00');

    // set the hours and minutes for the epoch
    startDate.setHours(parseInt(startHoursAndMins[0]), parseInt(startHoursAndMins[1]));
    endDate.setHours(parseInt(endHoursAndMins[0]), parseInt(endHoursAndMins[1]));

    const duration = endDate.getTime() - startDate.getTime(); // difference in milliseconds

    const durationInMinutes = Math.floor(duration / (1000 * 60));
    
    this.duration = durationInMinutes;

    const durationInHours = (durationInMinutes / 60).toFixed(2); // round to 2 decimal places

    Helper.removeCssClass("obs-duration", "validation-error");
    if (this.ObsDuration) {
      this.ObsDuration.nativeElement.value = `${durationInHours} hrs`;
    }
  }

  loadPostConferenceStartTimeDropdown() {
    let starttime = 7.00;
    this.postConferenceStartTimes.push('7.00 AM');

    for (let index = 0; index < 19; index++) {
      starttime = starttime + 0.15
      if (starttime.toFixed(2).toString().indexOf('.60') != -1) {
        starttime = Math.round(starttime);
      }
      this.postConferenceStartTimes.push(starttime.toFixed(2).toString() + ' AM');
    }

    for (let index = 20; index <= 59; index++) {
      starttime = starttime + 0.15
      if (starttime.toFixed(2).toString().indexOf('.60') != -1) {
        starttime = Math.round(starttime);
      }
      if (starttime == 13.00) {
        starttime = 1.00;
      }
      this.postConferenceStartTimes.push(starttime.toFixed(2).toString() + ' PM');
    }
  }

  loadPostConferenceEndTimeDropdown(event) {
    let isNoon = false;
    let isAfternoon = false;

    this.postConferenceEndTimes = [];
    let postConfStartTime = this.conPostStartTime.ResponseValue;

    if (postConfStartTime.indexOf('PM') != -1) {
      if (parseFloat(postConfStartTime.substring(0, 3)) == 12) {
        isNoon = true;
      }
      else {
        isAfternoon = true;
      }
    }

    let starttime = parseFloat(postConfStartTime.substring(0, postConfStartTime.indexOf(' ')));
    this.postConferenceEndTimes.push(postConfStartTime);

    for (let index = 0; index <= 59; index++) {
      starttime = starttime + 0.15
      if (starttime.toFixed(2).toString().indexOf('.60') != -1) {
        starttime = Math.round(starttime);
      }
      if (starttime == 12.00) {
        isNoon = true;
      }
      if (starttime == 13.00) {
        isAfternoon = true;
        starttime = 1.00;
      }

      if (isNoon || isAfternoon) {
        if (isAfternoon && starttime > 10.00) {
          break;
        }
        this.postConferenceEndTimes.push(starttime.toFixed(2).toString() + ' PM');
      }
      else {
        this.postConferenceEndTimes.push(starttime.toFixed(2).toString() + ' AM');
      }
    }
  }

  buildAlertEmailHTML() {
    this.alertName = this.getAlertName(this.obsAssessment.Alert.AlertTypeId);
    let html = `<p>Field Supervisor: ${this.fieldSupervisor.FirstName} ${this.fieldSupervisor.LastName} (${this.fieldSupervisor.PrimaryEmail})</p>`;
    html += `<p>Intern: ${this.lstcert.Intern.FirstName} ${this.lstcert.Intern.LastName} (${this.lstcert.Intern.Email})</p>`;
    html += `<p>Alert: ${this.alertName}</p>`;
    html += `<p>${this.obsAssessment.Alert.Description}</p>`;

    this.emailHtmlContent = html;
  }

  sendAlertEmail(): Observable<EmailModel> {

    let sender: EmailRecipient = new EmailRecipient;
    let destination: EmailRecipient = new EmailRecipient;

    //Send standard/default Alert Email
    this.buildAlertEmailHTML();
    destination.email = this.RECIPIENT_EMAIL_ADDRESS;
    destination.name = this.RECIPIENT_NAME;

    sender.email = this.fieldSupervisor.PrimaryEmail;
    sender.name = `${this.fieldSupervisor.FirstName} ${this.fieldSupervisor.LastName}`;

    let subject = ` Alert: ${this.alertName} from Field Supervisor ${this.fieldSupervisor.FirstName} ${this.fieldSupervisor.LastName}`;

    //Generate the Email Model (Subject, Sender, etc.)
    let newMailData = this.getEmailData(sender, destination, subject);

    //validate if there is a valid token to send the email
    if (this.token.length > 0) {
      //Calling service to send emails
      return this.emailService.SendEmail(newMailData, this.token);
    }
  }

  private sendTerminationAlert() {

    let sender: EmailRecipient = new EmailRecipient;
    let destination: EmailRecipient = new EmailRecipient;

    this.buildLeaveResignTerminateEmailHTML();

    //Generate email model specifics by Alert Type
    destination.email = this.isTexasObs ? this.TX_CC_EMAIL_ADDRESS : this.CERTIFICATION_EMAIL_ADDRESS;

    sender.email = this.NOREPLY_EMAIL_ADDRESS;
    sender.sendOnBehalf = true;

    let subject = `Field Supervisor Portal - Leave, Resign or Terminate Alert`;

    let terminationEmailAlertData = this.getEmailData(sender, destination, subject);

    //validate if there is a valid token to send the email
    if (this.token.length > 0) {
      //Calling service to send emails
      return this.emailService.SendEmail(terminationEmailAlertData, this.token).subscribe();
    }
  }

  private getEmailData(senderEmail: EmailRecipient, destinationEmail: EmailRecipient, subject: string): EmailModel {

    let emailData = new EmailModel();

    emailData.to.push(destinationEmail);
    emailData.from = senderEmail;
    emailData.subject = subject;
    emailData.htmlContent = this.emailHtmlContent;
    emailData.allRecipients = [];
    emailData.saveToSentFolder = true;

    return emailData;
  }

  saveAlertEmailNote(): void {
    let candidateNote: CandidateNote = {
      Note: this.obsAssessment.Alert.Description,
      Priority: false,
      CategoryId: 13,
      ProfileId: this.lstcert.Intern.profileId,
      CreateUser: this.authService.user.displayName,
      ModUser: null,
      AccountTypeId: 8
    }
    this.internNoteService.CreateInternNote(candidateNote).subscribe((result) => {
      this.snackBar.open("Note saved successfully", 'Okay');
    }, () => {
      this.openSnackBar('Note was not saved', 'Error');
    });
  }

  buildLeaveResignTerminateEmailHTML() {
    let html = `<p>The following was submitted from Field Supervisor Portal by: ${this.fieldSupervisor.FirstName} ${this.fieldSupervisor.LastName} (${this.fieldSupervisor.PrimaryEmail})</p>`;
    html += `<ul>`;
    html += `<li>Intern ID: ${this.lstcert.Intern.Identifier}</li>`;
    html += `<li>Intern Name: ${this.lstcert.Intern.FirstName} ${this.lstcert.Intern.LastName}</li>`;
    html += `<li>Contact Date: ${this.obsAssessment.Date}</li>`;
    html += `<li>Comment: ${this.obsAssessment.Alert.Description}</li>`;
    html += `</ul>`;

    this.emailHtmlContent = html;
  }

  openSnackBar(data: string, status: string) {
    this.snackBar.open(data, status, {
      duration: 5 * 1000,
    });
  }

  private get displaySaveBtnForObservation(): boolean {    
    var displaySaveButton = this.completed != 'true' && 
                           (this.obsAssessment.ContactTypeId == ContactTypeEnum.Observation ||
                            this.obsAssessment.ContactTypeId == ContactTypeEnum.InformalObservation ||
                            this.obsAssessment.ContactTypeId == ContactTypeEnum.Conference);

    return displaySaveButton;
  }

  private get displaySaveBtnForNonObservation(): boolean {
    var displaySaveButton = this.completed != 'true' && 
                           (this.obsAssessment.ContactTypeId != ContactTypeEnum.Observation && 
                            this.obsAssessment.ContactTypeId != ContactTypeEnum.InformalObservation && 
                            this.obsAssessment.ContactTypeId != ContactTypeEnum.Conference);
    return displaySaveButton;
  }

  private get displayFinishBtnForObservation(): boolean {
    var displayFinishButton = (this.completed != 'true' || 
                               this.isClassRoomSupport == true) &&
                              ((this.obsAssessment.ContactTypeId == ContactTypeEnum.Observation && !this.blockObservationCreation) || 
                               this.obsAssessment.ContactTypeId == ContactTypeEnum.InformalObservation || 
                               this.obsAssessment.ContactTypeId == ContactTypeEnum.Conference);

    return displayFinishButton;
  }

  private getAlertName(typeId: number): string {
    if (typeId == ObsAlertTypeEnum.QualityIssueWithIntern) {
      return "Quality issue with intern";
    }
    else if (typeId == ObsAlertTypeEnum.BragAboutThisIntern) {
      return "Brag about this intern";
    }
    else if (typeId == ObsAlertTypeEnum.SafetyConcern) {
      return "Safety concern";
    }
    else if (typeId == ObsAlertTypeEnum.Miscellaneous) {
      return "Miscellaneous";
    }
    else if (typeId == ObsAlertTypeEnum.LeaveResignOrTerminate) {
      return "Leave, Resign or Terminate";
    }
    else if (typeId == ObsAlertTypeEnum.Collaboration) {
      return "Collaboration";
    }
    else {
      return "Invalid Alert Type";
    }
  }

  private invalidateField(elementId: string, message: string): void {

    this.formValidForSave = false;
    this.formValidForFinalize = false;
    this.responseValidationFeedback.push(message);
    Helper.addCssClass(elementId, "validation-error");
  }

  private restrictWeekendsForObservations(): boolean {
    var day = new Date(this.obsAssessment.Date).getDay();

    if ((day === DayEnum.Saturday || day === DayEnum.Sunday) &&
      (Number(this.obsAssessment.ContactTypeId) === ContactTypeEnum.Observation || Number(this.obsAssessment.ContactTypeId) === ContactTypeEnum.MeetAndGreet)) {

      this.invalidateField("obsAssessment-date", 'Contact date cannot be on a weekend');

      return true;
    }
    Helper.removeCssClass("obsAssessment-date", "validation-error");
    return false;
  }

  convertTo24HourFormat(timeString) {
    if (timeString.includes('.')) {
      let [time, modifier] = timeString.split(' ');
      let [hours, minutes] = time.split('.');
      if (modifier && hours && minutes && modifier.toLowerCase() === "pm") {
        hours = (parseInt(hours, 10) + 12).toString();
      }
      return `${hours}:${minutes}`;
    } else {
      return timeString;
    }
  }

  informalObservationChanged(state: InformalObservationState) {
    this.informalObservationState = state;
  }

  conferenceChanged(state: ConferenceState) {
    this.conferenceState = state;
  }
}


