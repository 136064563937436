export class Attachment {    
  contentType: string;  
  isInline: boolean;
  lastModifiedDateTime: Date;
  name: string;
  size: number;
}


export class FileAttachment extends Attachment {
  contentId: string;
  contentLocation: string;
  contentBytes: any;
}
