export class Helper {
    public static removeCssClass(elementId: string, className: string) {
        const elem = document.getElementById(elementId);
        
        if(elem && elem.classList.contains(className)) {
            elem.classList.remove(className);
        }
    }
    
    public static  addCssClass(elementId: string, className: string) {
        const elem = document.getElementById(elementId);
        
        if(elem && !elem.classList.contains(className)) {
            elem.classList.add(className);
        }
    }
}