// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#message {
  font-size: large;
  width: 650px;
  margin: 100px auto;
  padding: 30px;
  min-height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/lockout-notification/lockout-notification.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;AACJ","sourcesContent":["#message{\n    font-size: large;\n    width: 650px;\n    margin: 100px auto;\n    padding: 30px;\n    min-height: 100px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
