// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingMessage {
  width: 100px;
  margin: 2rem auto;
}

button[type=button] {
  border-radius: 30px;
  height: 36px;
  border: 0;
  width: 55%;
  padding-left: 15px;
  color: white;
  font-size: 10px;
  letter-spacing: 0.1px;
  background-color: #023970;
  cursor: pointer;
}

.pull-right {
  float: right;
}

.checklists {
  padding: 10px 5px;
}
.checklists .checklistItem .cheklistItemIcon {
  color: #003A71;
}
.checklists .checklistItem .checklistItem--description {
  display: inline-block;
  position: relative;
  top: -7px;
  padding-left: 8px;
}

.buttonSection {
  height: 36px;
  margin: 0 10px 10px 0;
}
.buttonSection button {
  width: 100px;
  font-size: inherit;
  padding-left: 0;
  letter-spacing: initial;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/intern/intern-whats-next/intern-whats-next.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,qBAAA;EACA,yBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,iBAAA;AACF;AACI;EACE,cAAA;AACN;AACI;EACE,qBAAA;EACA,kBAAA;EACA,SAAA;EACA,iBAAA;AACN;;AAIA;EACE,YAAA;EACA,qBAAA;AADF;AAEE;EACE,YAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;EACA,UAAA;AAAJ","sourcesContent":[".loadingMessage {\n  width: 100px;\n  margin: 2rem auto;\n}\n\nbutton[type=button] {\n  border-radius: 30px;\n  height: 36px;\n  border: 0;\n  width: 55%;\n  padding-left: 15px;\n  color: white;\n  font-size: 10px;\n  letter-spacing: 0.1px;\n  background-color: #023970;\n  cursor: pointer;\n}\n\n.pull-right {\n  float: right;\n}\n\n.checklists {\n  padding: 10px 5px;\n  .checklistItem {\n    .cheklistItemIcon {\n      color: #003A71;\n    }\n    .checklistItem--description {\n      display: inline-block;\n      position: relative;\n      top: -7px;\n      padding-left: 8px;\n    }\n  }\n}\n\n.buttonSection {\n  height: 36px;\n  margin: 0 10px 10px 0;\n  button {\n    width: 100px;\n    font-size: inherit;\n    padding-left: 0;\n    letter-spacing: initial;\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
