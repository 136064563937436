import { Injectable, Inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Client } from '@microsoft/microsoft-graph-client';
import { User, LoggedinUserInfo } from '../shared/models/user';
import { AlertsService } from 'src/app/services/alerts.service';
import { GlobalService } from './global.service';
import { shareReplay, catchError, retry } from 'rxjs/operators';
//import { HttpClient } from 'selenium-webdriver/http';
import { EMPTY } from 'rxjs';
import { Authorization_GetRoles_Options } from '../shared/models/authorization.model';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { environment } from '../../environments/environment';
import { AuthorizationService } from './authorization.service';
import { Role } from '../shared/models/role.model';
import { AuthComponent } from '../auth/auth.component';
import { resolve } from 'url';
import { AccountInfo, SilentRequest } from '@azure/msal-browser';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

  public authenticated = false;
  public user: User;
  loggedinUserInfo: LoggedinUserInfo = null;
  public wrappedEmail: Authorization_GetRoles_Options = new Authorization_GetRoles_Options() ;
    apiUrl = '';
  account: any;
  accessToken: any;

  constructor(
    private httpClient: HttpClient,
    private msalService: MsalService,
    private alertsService: AlertsService,
    private configSvc: ConfigService,
    private authorizationService: AuthorizationService,
    private globalService: GlobalService) {

    this.apiUrl = this.configSvc.apiUrl;
    this.getUser().then((user) => { this.user = user });
  }

  async initializeUser(account: AccountInfo): Promise<User> {
    return await new Promise((resolve, reject) => {
      this.account = account;
      console.log(account);
      this.user = new User();
      this.user.email = <string><unknown>this.account.username;
      this.user.displayName = <string><unknown>this.account.name;

      if (!!this.account && !this.accessToken) {
        console.log('getting AccessToken...');
        this.getAccessTokenForAPI().then(result => {
          console.log('getAccessToken result:', result);
          this.accessToken = result;
        })
        .then( () => {
          console.log('Getting roles')
          return this.GetUserRoles(this.user).then(roles => {
            this.user.roles = roles;
            return roles;
            // console.log("User Roles:", roles);
            // localStorage.setItem("fsRoles", JSON.stringify(data));
            // console.log("Just set user fsRoles for", user);
          });
        })
        .then((roles) => {
          this.user.roles = roles;
          this.authenticated = true;
          console.log('resolving with ', this.user);
          resolve(this.user);
        })
        .catch(error => {
          console.error('Error: getAccessTokenForAPI '+error);
          reject("Failed to initialize user:"+error);

        })

      }
    });
  }



  GetUserRoles(user: User)  {
    const options: Authorization_GetRoles_Options = {
      AuthenticatedEmailAddress: user.email
    };
    return this.Authorization_GetRoles(options).toPromise();
  }

  loadUserProfile(emailAddress: string) {
    throw new Error('Method not implemented.');
  }

  resetPassword () {
    // if (this.configSvc.isIE || this.configSvc.fullPageLogin) {
    //   this.msalService.loginRedirect(this.configSvc.b2cPolicies.authorities.resetPassword);
    // } else {
    //   this.msalService.loginPopup(this.configSvc.b2cPolicies.authorities.resetPassword);
    // }
  }

    // Sign out
  signOut(): void {
        localStorage.removeItem("fsRoles"); // We need to clear this but it doesn't load fast enough to be ready
        this.msalService.logout();
        this.user = null;
        this.authenticated = false;
        this.globalService.clear();
  }


  public GetUserInfo() {
    if (this.authenticated) {
      const secondObservable = this.httpClient.get<any>(`${this.apiUrl}/userinfo/GetUserInfo?email=${this.user.email}`).pipe(
        retry(3),
        catchError(error => {
          return EMPTY;
        }),
        shareReplay()
      );
      return secondObservable;
    }
    return null;
  }

    // Silently request an access token
    async getAccessToken(): Promise<string> {
      const params: SilentRequest = {
        scopes: this.configSvc.scopes,
        account: this.account
      } ;
      return new Promise((resolve, reject) => {
        this.msalService.acquireTokenSilent(params).subscribe(authResponse => {
          resolve(authResponse.accessToken);
        },
        (error) => {
          reject(error);
        }
        );
      });

    }

    public async getAccessTokenForAPI(): Promise<string>  {
      const params: SilentRequest = {
        scopes: this.configSvc.scopeForAPI,
        account: this.account
      };
      return new Promise((resolve, reject)=> {
        this.msalService.acquireTokenSilent(params).toPromise().then(authResponse => {
          resolve( authResponse.accessToken );
        }).catch((error) => {
          reject(error);
        });
      });

  }

    public async getUser(): Promise<User> {
        if (!this.authenticated) return null;

        let graphClient = Client.init({
            // Initialize the Graph client with an auth
            // provider that requests the token from the
            // auth service
            authProvider: async (done) => {
                let token = await this.getAccessToken()
                    .catch((reason) => {
                        done(reason, null);
                    });

                if (token) {
                    done(null, token);
                } else {
                    done("Could not get an access token", null);
                }
            }
        });

        // Get the user from Graph (GET /me)
      let graphUser = await graphClient.api('/me').get();
        let user = new User();
      user.displayName = graphUser.displayName;
      // Prefer the userPrincipalName property, but fall back to mail
      user.email = graphUser.userPrincipalName || graphUser.mail;

      return user;
  }

  public async  GetRoles(user: User) {
    this.wrappedEmail.AuthenticatedEmailAddress = user.email;
    console.log("User email", user.email);
    this.Authorization_GetRoles(this.wrappedEmail).subscribe(

      data => {
        user.roles = data;
        console.log("User Data", data);
        localStorage.setItem("fsRoles", JSON.stringify(data));
        console.log("Just set user fsRoles for", user);

      }
    );
    return true;
  }

    public async getUsers(filter: string): Promise<User[]> {
        let graphClient = Client.init({
            // Initialize the Graph client with an auth
            // provider that requests the token from the
            // auth service
            authProvider: async (done) => {
                let token = await this.getAccessToken()
                    .catch((reason) => {
                        done(reason, null);
                    });

                if (token) {
                    done(null, token);
                } else {
                    done("Could not get an access token", null);
                }
            }
        });

        let users: User[] = [];

        // Get the user from Graph (GET /me)
        await graphClient
            .api('/users')
            .filter(filter)
            .top(20)
            .get().then(result => {
                users = result;
            });

        return users;
    }

  //Client method to get roles from Prince database
  public Authorization_GetRoles(Authorization_GetRoles_byEmail: Authorization_GetRoles_Options) {
    return this.httpClient.post<Role[]>(`${this.configSvc.apiUrl}/v1.0/Authorization/GetRoles`, Authorization_GetRoles_byEmail);
  }
}
