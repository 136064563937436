export enum ObsQuestionEnum {
    TeachersOfTomorrowPendingRequirements = 51,
    OverallImpression = 52,
    PreConferenceDate = 53,
    PreStartTime = 54,
    PreEndTime = 55,
    PostConferenceDate = 56,
    PostStartTime = 57,
    PostEndTime = 58,
    Contact = 59,
    Strengths = 60,
    AreasOfGrowth = 61,
    ActionableSteps = 62,
    OtherNotes = 63,
    DiscussionFromMeeting = 64
}