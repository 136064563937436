export class recommendation {
  CertProgramId: number = 0;
  RecommendationTypeId: number = 0;
  CertificateName: string = null;
  SortOrder: number = 0;
  FsName: string = null;
  FsLastName: string = null;
  Date: string = null;
  Comment: string = null;
  CoachTypeId: number = 0;
  CreateUser: string = null;
}
