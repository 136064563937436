import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { retry, catchError, shareReplay } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  apiUrl: string = '';

  constructor(private httpClient: HttpClient,
    private configSvc: ConfigService) {
    this.apiUrl = this.configSvc.apiUrl;
  }

  public getAppSettingValue(category: string, key: string): Promise<any> {
    return this.httpClient.get<string>(`${this.apiUrl}/v1.0/admin/app-setting/${category}/${key}`).toPromise();
  }

}
