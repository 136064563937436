// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  padding: 10px 0;
  margin: 15px 0 0 0;
  font-weight: bold;
}

input[type=file] {
  display: inline-block;
}

#file-input {
  width: 300px;
  padding: 0 10px;
}

.label {
  padding: 0 8px;
}

a.customLink {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 18px;
  margin-right: 25px;
  margin-left: 5px;
  min-width: 100px;
  display: inline-block;
}

mat-icon {
  color: #023970;
  vertical-align: middle;
}

button[type=button] {
  border-radius: 30px;
  height: 36px;
  border: 0;
  width: 110px;
  color: white;
  font-size: 14px;
  letter-spacing: 0.1px;
  background-color: #023970;
  cursor: pointer;
  margin-right: 25px;
}

.emphasis {
  font-weight: bold;
}

.invalid-file-type {
  background-color: #F9FF8E;
  padding: 3px;
  border-radius: 4px;
}

#busy-indicator {
  display: inline-block;
  width: 150px;
  margin: 0 15px;
}

.validation-error {
  border: 1px solid #f00 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/intern/observation/observation-attachment/observation-attachment.component.scss"],"names":[],"mappings":"AAGA;EACI,eAAA;EACA,kBAAA;EACA,iBAAA;AAFJ;;AAIA;EACI,qBAAA;AADJ;;AAGA;EACI,YAAA;EACA,eAAA;AAAJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,0BAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,qBAAA;AACJ;;AAEE;EACE,cAAA;EACA,sBAAA;AACJ;;AAEE;EACE,mBAAA;EACA,YAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,qBAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AAEE;EACI,iBAAA;AACN;;AACE;EACI,yBAAA;EACA,YAAA;EACA,kBAAA;AAEN;;AACA;EACE,qBAAA;EACA,YAAA;EACA,cAAA;AAEF;;AACA;EACE,iCAAA;AAEF","sourcesContent":["#container {\n   \n}\n.title {\n    padding: 10px 0;\n    margin: 15px 0 0 0;\n    font-weight: bold;\n}\ninput[type=file] {\n    display: inline-block;\n}\n#file-input {\n    width: 300px;\n    padding: 0 10px;\n}\n.label {\n    padding: 0 8px;\n}\n\na.customLink {\n    text-decoration: underline;\n    color: blue;\n    cursor: pointer;\n    font-size: 18px;\n    margin-right: 25px;\n    margin-left: 5px;\n    min-width: 100px;\n    display: inline-block;\n  }\n\n  mat-icon {\n    color: #023970;\n    vertical-align: middle;\n  }\n\n  button[type=button] {\n    border-radius: 30px;\n    height: 36px;\n    border: 0;\n    width: 110px;\n    color: white;\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    background-color: #023970;\n    cursor: pointer;\n    margin-right: 25px;\n  }  \n\n  .emphasis {\n      font-weight: bold;\n  }\n  .invalid-file-type {\n      background-color: #F9FF8E;\n      padding: 3px;\n      border-radius: 4px;\n  }\n\n#busy-indicator {\n  display: inline-block;\n  width: 150px;\n  margin: 0 15px;\n}\n\n.validation-error {\n  border: 1px solid #f00 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
