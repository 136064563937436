import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { firstValueFrom, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  configuration: any;
  httpClient: HttpClient;
  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler); // We cannot inject HttpClient directly because Http interceptors rely on http client. For details see https://stackoverflow.com/a/49013534/37759
  }

  async loadConfigs() {
    await  firstValueFrom( this.httpClient.get<any>(window.location.origin + '/assets/config.json')
      .pipe(
        tap(config => {
          this.configuration = config;
          console.log('this.configuration', this.configuration);
        })
      ));
  }

  getMsalConfiguration(): IPublicClientApplication  {
    const config= {
      auth:{
        clientId: this.configuration.clientId,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false
      },
      system: {
        loggerOptions: {
            allowNativeBroker: false,
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
      }
    };
    return new PublicClientApplication(config);
  }

  public getMsalInterceptorConfiguration(): MsalInterceptorConfiguration  {
    console.log('getMsalInterceptorConfiguration called');
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', this.scopes);
    protectedResourceMap.set(this.apiUrl, this.scopeForAPI);

    const angularConfig: MsalInterceptorConfiguration = {
      interactionType: InteractionType.Popup,
      protectedResourceMap: protectedResourceMap
    };
    return (angularConfig as MsalInterceptorConfiguration);
  }
  public getMsalGuardConfiguration(): MsalGuardConfiguration   {
    console.log('getMsalGuardConfiguration called');
    return  {
      interactionType: InteractionType.Popup,
      authRequest: {
        scopes: this.scopes
      },
      loginFailedRoute: '/failed'
    };
  }

  get apiUrl() {
    return this.configuration.apiUrl;
  }
  get emailServerURL() {
    return this.configuration.EmailServerURL;
  }
  get authServerURL() {
    return this.configuration.AuthServerURL;
  }

  get scopes() {
    return ["user.read", "email", "mail.send"];
  }

  get scopeForAPI() {
    return ["api://6a5346a4-e708-44e5-b723-bb845f2433d8/Read"];
  }

}

