import { EmailRecipient } from './email-recipient';
import { Attachment } from './attachment';

export class EmailModel {
  to: EmailRecipient[] = [];
  cc: EmailRecipient[] = [];
  bcc: EmailRecipient[] = [];
  from: EmailRecipient;
  subject: string;
  body: string;
  htmlContent: string;
  plainTextContext: string;
  saveToSentFolder: boolean;
  referenceId: string;
  response: string;
  highImportance: boolean;
  allRecipients: EmailRecipient[] = [];
  sent: boolean;
  emailPerReceipient: boolean | null;
  processReplacements: boolean | null;
  hasAttachments: boolean | null;
  attachments: Attachment[] = [];
}
