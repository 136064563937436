import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { FieldSupervisor } from '../shared/models/fsupervisor.model';
import { retry, catchError, shareReplay } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { FieldSupervisorCandidateDTO } from '../shared/models/fieldSupervisorCandidateDTO';

@Injectable({
  providedIn: 'root'
})

export class FSupervisorService {

  headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  constructor(private httpClient: HttpClient, private configService: ConfigService) { }

    public getAvailableFieldSupervisors(): Observable<FieldSupervisor[]> {    
      return this.httpClient.get<FieldSupervisor[]>(`${this.configService.apiUrl}/v1.0/FieldSupervisors/`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public getFieldSuperisorbyEmail(emailAddress: string) {    
    return this.httpClient.get<FieldSupervisor>(`${this.configService.apiUrl}/v1.0/FieldSupervisors/Email/` + emailAddress).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public getCandidatesForFieldSupervisor(fieldSupervisorId: number){
    return this.httpClient.get<FieldSupervisorCandidateDTO[]>(`${this.configService.apiUrl}/FieldSupervisors/GetCandidatesForFieldSupervisor/${fieldSupervisorId}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }
}
