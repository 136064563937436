import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ObsResponses } from 'src/app/shared/models/contactResponses';
import { Helper } from 'src/utility/Helper';

@Component({
  selector: 'app-observation-professional',
  templateUrl: './observation-professional.component.html',
  styleUrls: ['./observation-professional.component.scss']
})
export class ObservationProfessionalComponent implements OnInit {

  obsPlanning=[{
    "id":"41",
    "initialVal":0,
    "title":"Dimension 4.1: Professional Demeanor and Ethics",
    "desc":"The teacher meets district expectations for attendance, professional appearance, decorum, procedural, ethical, legal, and statutory responsibilities.<br><br>A rating of “Did Not Observe at this Time” is neither a positive nor a negative assessment, but is related to unobservable behavior.",
    "instruction":"<b>The teacher:</b><br><br>•Responds to all communication between Field Supervisor (FS) and Texas Teachers in a timely manner<br><br>•Displays professional appearance and demeanor<br><br>•Provides model to students on expected behavior in and out of the classroom"
  },
  {
    "id":"42",
    "initialVal":3,
    "title":"Dimension 4.2: Goal Setting",
    "desc":"The teacher reflects on his/her practice.<br><br>A rating of “Did Not Observe at this Time” is neither a positive nor a negative assessment, but is related to unobservable behavior.",
    "instruction":"<b>The teacher:</b><br><br>•Holds high standards for individual development and refinement<br><br>•Reflects and applies FS/Administrator feedback and student data results"
  },
  {
    "id":"43",
    "initialVal":6,
    "title":"Dimension 4.3: Professional Development",
    "desc":"The teacher enhances the professional community.<br><br>A rating of “Did Not Observe at this Time” is neither a positive nor a negative assessment, but is related to unobservable behavior.",
    "instruction":"<b>The teacher:</b><br><br>•Collaborates with colleagues and FS to gain a better understanding of the learning process<br><br>•Receptive to suggestions and feedback from FS"
  },
  {
    "id":"44",
    "initialVal":9,
    "title":"Dimension 4.4: School Community Involvement",
    "desc":"The teacher demonstrates leadership with students, colleagues, and community members in the school, district, and community through effective communication and outreach.<br><br>A rating of “Did Not Observe at this Time” is neither a positive nor a negative assessment, but is related to unobservable behavior.",
    "instruction":"<b>The teacher:</b><br><br>•Takes interest in students’ overall achievements<br><br>•Communicates vision/goals to enhance student learning and welfare by utilizing community members and colleagues"
  }];
  
  @Input() Responses: ObsResponses[] = [];
  @Output() onProfessionalDataChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.LoadResponse();
    if (this.Responses.length > 0) {
      this.onProfessionalDataChange.emit(this.Responses);
    }
  }

  EmitChildData(index: number, type: string) {
    if (this.Responses.length > 0) {
      this.onProfessionalDataChange.emit(this.Responses);
    }
    const dimensionId: string = `dimension-4.${index+1}-${type}`;
    Helper.removeCssClass(dimensionId, "validation-error");

    if (this.Responses.length > 0 && type === 'proficiency') {
      if (this.Responses[index*3].ResponseValue === "0") {
        Helper.removeCssClass(`dimension-4.${index+1}-evidence`, 'validation-error');
      }
    }
  }

  LoadResponse(){
    
    let count=38;

    if (this.obsPlanning.length <= 4 && this.Responses.length <= 0) {
      for (let index = 0; index < this.obsPlanning.length; index++) {
        const element = this.obsPlanning[index];

        count += 1;
        let obRes = new ObsResponses();
        obRes.QuestionId = count;
        obRes.ControlType = "Ranking";
        obRes.ResponseValue = "";

        count += 1;
        let obRes1 = new ObsResponses();
        obRes1.QuestionId = count;
        obRes1.ControlType = "FreeTextForm";
        obRes1.ResponseValue = "";

        count += 1;
        let obRes2 = new ObsResponses();
        obRes2.QuestionId = count;
        obRes2.ControlType = "FreeTextForm";
        obRes2.ResponseValue = "";

        this.Responses.push(obRes);
        this.Responses.push(obRes1);
        this.Responses.push(obRes2);

      }
    }
  }
}
