import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InternService } from 'src/app/services/intern.service';
import { Intern } from 'src/app/shared/models/intern';
import { internCertDetails } from 'src/app/shared/models/internCertDetails';
import { MentorObservation } from 'src/app/shared/models/mentor-observation.model';
import * as FileSaver from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'mentor-observations',
  templateUrl: './mentor-observations.component.html',
  styleUrls: ['./mentor-observations.component.scss']
})
export class MentorObservationsComponent implements OnInit {
  private profileId: number;
  private _certDetails: internCertDetails;
  mentorObservationsDataSource: MatTableDataSource<MentorObservation>;
  noData: boolean;
  mentorObservationsLoading: boolean;
  mentorObservationsDisplayedColumns: string[] = ['actions','observationDate','semester','mentor'];

  @Input()
  set certList(value: internCertDetails){
    this._certDetails = value;
    
    if (!!this._certDetails.Intern){
      this.profileId = this._certDetails.Intern.profileId;
      this.LoadMentorObservations(this.profileId);
    }
  }

  constructor(private internService:InternService, private snackBar: MatSnackBar, ) { }

  private openSnackBar(data: string, status: string) {
    this.snackBar.open(data, status, {
        duration: 5 * 1000,
    });
  }

  ngOnInit(): void {
  }

  LoadMentorObservations(profileId: number) {
    this.mentorObservationsLoading = true;
    
    this.internService.getObservations(profileId).toPromise()
    .then(observations => {
      this.mentorObservationsLoading = false;
      this.mentorObservationsDataSource = new MatTableDataSource<MentorObservation>(observations);
      this.noData =  this.mentorObservationsDataSource && this.mentorObservationsDataSource.data.length == 0;
    })
    .catch(error => {
      this.mentorObservationsLoading = false;
      this.noData = true;
      console.error(error);
    });
  }

  canDownload(observation: MentorObservation)
  {
    return !!observation.MentorObservationDocumentDTO?.id;
  }

  download(observation: MentorObservation)
  {
    this.internService.downloadMentorObservationDocument(observation.MentorObservationDocumentDTO.id).toPromise()
    .then(fileContent => {
      FileSaver.saveAs(fileContent, observation.MentorObservationDocumentDTO.filename);	
    })
    .catch(error =>{
      this.openSnackBar('The file could not be downloaded.', null);
      console.error(error);        
    });;
  }
}
