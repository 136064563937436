// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentor-observations--head th {
  background-color: #f0f0f0;
  color: #999;
  font-weight: 600;
}

th.action-column {
  text-align: center;
}

td.action-column {
  width: 135px;
  text-align: center;
}

td.date-column {
  width: 105px;
  text-align: center;
}

td.semester-column, th.semester-column {
  width: 100px;
  text-align: center;
}

td.mentor-column, th.mentor-column {
  width: 150px;
  text-align: center;
}

.loadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/intern/mentor-observations/mentor-observations.component.scss"],"names":[],"mappings":"AAIA;EACI,yBAAA;EACA,WAAA;EACA,gBAAA;AAHJ;;AAMA;EACI,kBAAA;AAHJ;;AAOA;EACI,YAAA;EACA,kBAAA;AAJJ;;AAOA;EACI,YAAA;EACA,kBAAA;AAJJ;;AAOA;EACI,YAAA;EACA,kBAAA;AAJJ;;AAOA;EACI,YAAA;EACA,kBAAA;AAJJ;;AAOA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AAJJ","sourcesContent":[".mentor-observations {\n\n}\n\n.mentor-observations--head th {\n    background-color: #f0f0f0;\n    color: #999;\n    font-weight: 600;\n}\n\nth.action-column {\n    text-align: center;  \n    \n}\n\ntd.action-column {\n    width: 135px;\n    text-align: center;  \n}\n\ntd.date-column {\n    width: 105px;\n    text-align: center;  \n}\n\ntd.semester-column, th.semester-column {\n    width: 100px;\n    text-align: center;  \n}\n\ntd.mentor-column, th.mentor-column {\n    width: 150px;\n    text-align: center;  \n}\n\n.loadingSpinner {\n    display: flex;\n    align-items: center;\n    justify-content: center;   \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
