// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-form {
  width: 100%;
  background-color: #fafafa;
  padding-bottom: 20px;
  flex-flow: column;
  height: 100%;
  flex: 1 1 auto;
}

button {
  margin-left: 15px;
  border-radius: 30px;
  width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/auth.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,oBAAA;EACA,iBAAA;EACA,YAAA;EACA,cAAA;AACF;;AAGA;EACE,iBAAA;EACA,mBAAA;EACA,YAAA;AAAF","sourcesContent":[".auth-form {\n  width: 100%;\n  background-color: #fafafa;\n  padding-bottom: 20px;  \n  flex-flow: column;\n  height: 100%;\n  flex: 1 1 auto;\n}\n\n//Styling buttons with round corners\nbutton {\n  margin-left: 15px;\n  border-radius: 30px;\n  width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
