// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actions button {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/confirm-dialog/confirm-dialog.component.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ","sourcesContent":["\n\n.actions button{\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
