// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
  border-radius: 35px;
  width: 95%;
  min-width: 400px;
  height: 38px;
  border: 1px solid #ccc;
  padding-left: 15px;
}

::ng-deep .mat-form-field-underline {
  display: none;
}

button {
  margin-left: 15px;
  border-radius: 35px;
  width: 80px;
}

::ng-deep .mat-select-trigger {
  margin-top: 3%;
  margin-left: 3%;
}

::ng-deep .mat-form-field-label {
  margin-top: 3%;
  margin-left: 3%;
}

::ng-deep .mat-input-element {
  margin-top: 3%;
  margin-left: 3%;
}

mat-form-field {
  width: 250px;
}

mat-grid-tile {
  min-width: 300px;
  min-height: 80px;
}

th {
  background-color: #f0f0f0;
  color: #a3a3a3;
  font-size: 14px;
  font-weight: 300 !important;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

td {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  font-weight: 400;
  text-align: left;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/select-supervisor/select-supervisor.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,UAAA;EACA,gBAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAGA;EACE,aAAA;AAAF;;AAIA;EACE,iBAAA;EACA,mBAAA;EACA,WAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,yBAAA;EACA,cAAA;EACA,eAAA;EACA,2BAAA;EACA,kBAAA;EACA,iBAAA;EACA,oBAAA;AADF;;AAIA;EACE,+BAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;EACA,6BAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AADF","sourcesContent":["input {\n  border-radius: 35px;\n  width: 95%;\n  min-width: 400px;\n  height: 38px;\n  border: 1px solid #ccc;\n  padding-left: 15px;\n}\n\n//Remove underline from input form fields\n::ng-deep .mat-form-field-underline {\n  display: none;\n}\n\n//Styling buttons with round corners\nbutton {\n  margin-left: 15px;\n  border-radius: 35px;\n  width: 80px;\n}\n\n::ng-deep .mat-select-trigger {\n  margin-top: 3%;\n  margin-left: 3%;\n}\n\n::ng-deep .mat-form-field-label {\n  margin-top: 3%;\n  margin-left: 3%;\n}\n\n::ng-deep .mat-input-element {\n  margin-top: 3%;\n  margin-left: 3%;\n}\n\nmat-form-field {\n  width: 250px;\n}\n\nmat-grid-tile {\n  min-width: 300px;\n  min-height: 80px;\n}\n\nth {\n  background-color: #f0f0f0;\n  color: #a3a3a3;\n  font-size: 14px;\n  font-weight: 300 !important;\n  text-align: center;\n  padding-top: 15px;\n  padding-bottom: 15px;\n}\n\ntd {\n  font-family: 'Lato', sans-serif;\n  font-size: 14px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  border-bottom: 1px solid #ccc;\n  font-weight: 400;\n  text-align: left;\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
