export class internDetails {
  Identifier: number = 0;
  LastName: string = null;
  FirstName: string = null;
  StreetAddress: string = null;
  City: string = null;
  State: string = null;
  ZipCode: string = null;
  Phone: string = null;
  Email: string = null;
  AdmissionDate: string = null;
  CertificateAreas: string[];
  RuleSet: string = null;
  MobilePhone: string = null;
  AltEmail: string = null;
  StateIdentifier: string = null;
  profileId: number = 0;
  profileState: string = null;
  MentorAssignedEmailAddress: string = null;
  MentorAssignedFirstName: string = null;
  MentorAssignedLastName: string = null;
  MentorAssignedPhoneNumber: string = null;
  MentorAssignedStateLicenseId: string = null;
}
