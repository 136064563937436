import { Component, OnInit, Input, Output } from '@angular/core';
import { AssessmentAttachment } from '../../../shared/models/assessment-attachment.model';
import { ObservationService } from '../../../services/observation.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { saveAs } from 'file-saver';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'observation-attachment',
  templateUrl: './observation-attachment.component.html',
  styleUrls: ['./observation-attachment.component.scss']
})
export class ObservationAttachmentComponent implements OnInit {
  attachment: AssessmentAttachment;
  replaceAttachment = false;
  invalidFileType = false;
  assessmentID: number;
  fileInput: any;
  busyMessage: string;
  isBusy = false;
  isReadOnly: boolean = false;;
  isExpansionState: boolean = false;
  editableStatuses: number[] = [1, 2, 3]

  constructor(private observationService: ObservationService, private snackbar: MatSnackBar, private dialog: MatDialog) { }

  @Input() set Attachment(attachment: AssessmentAttachment) {
    this.attachment = attachment;
    this.attachmentChange.emit(this.attachment);
    console.log('updated attachment', this.attachment);
  }

  @Input() set Completed(isCompleted: string) {
    this.isReadOnly = (isCompleted === 'true');
  }

  @Input() set StatusId(statusId: number) {
    //console.log('ObservationAttachmentComponent statusId', statusId);
    this.isReadOnly = !this.editableStatuses.includes(statusId) && statusId !== undefined;
  }

  @Input() set IsTexasObservation(isTexas: boolean) {
    this.isExpansionState = !isTexas;
  }

  @Output() attachmentChange: EventEmitter<AssessmentAttachment> = new EventEmitter();

  ngOnInit() {
  }

  busy(message: string) {
    this.busyMessage = message;
    this.isBusy = true;
  }

  notBusy() {
    this.isBusy = false;
    this.busyMessage = '';
    
  }

  download() {
    const filename = this.attachment.displayFilename;
    this.observationService.DownloadAttachment(this.attachment.obsAssessmentID).toPromise()
      .then(blob => {
        saveAs(blob, filename);
      })
      .catch(() => {
        this.openSnackBar('Error: Document download failed.', null);
      });
  }

  fileInputChange(event: any) {
    this.fileInput = event.target;

    if (this.fileInput.files.length > 0) {
      if (this.fileInput.files[0].type !== 'application/pdf'){
        this.openSnackBar('Please select .PDF file.', null);
        this.invalidFileType = true;
        return;
      }
      
      this.invalidFileType = false;

      if (this.attachment.filename && this.attachment.filename.length > 0) {
        this.verifyReplaceAction(this.attachment.displayFilename, this.fileInput.files[0].name);
      }
      else {        
        this.upload();
      }      
    }    
  }

  verifyReplaceAction(existingFilename: string, newFilename: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '380px',
      data: {
        title: 'Replace File',
        message: `Replace "${existingFilename}" with "${newFilename}"?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.upload();
      }
    });
  }

  upload() {
    const formData = new FormData();
    let id: number = undefined;
    if (this.attachment && this.attachment.obsAssessmentID) {
      id = this.attachment.obsAssessmentID
    }
    
    const modifiedfilename = id.toString() + '-' + this.fileInput.files[0].name;

    formData.append("ObservationAssesmentID", id.toString());
    formData.append("file", this.fileInput.files[0], modifiedfilename);

    if (this.replaceAttachment) {
      this.busy('Replacing file...');
      formData.append("ExistingFileName", this.attachment.filename);

      this.observationService.ReplaceAttachment(formData).toPromise().then(result => {
        this.notBusy();
        //this.attachment = result;
        this.updateAttachment(result);
        this.attachment.validationError = false;
        this.replaceAttachment = false;
        //console.log(result);
      }).catch(() => {
        this.notBusy();
        this.openSnackBar('Error: The observation document was not replaced.', null);
      });
    }
    else {
      this.busy('Saving file...');
      this.observationService.UploadAttachment(formData).toPromise().then(result => {
        this.notBusy();
        this.updateAttachment(result);
        this.attachment.validationError = false;
        console.log('upload result', result);
        console.log('upload this.attachment', this.attachment);
      }).catch(() => {
        this.notBusy();
        this.openSnackBar('Error: The observation document was saved.', null);
      });
    }

    
  }
  updateAttachment(result: AssessmentAttachment) {
    
    const attachment = new AssessmentAttachment(result.filename, result.obsAssessmentID);
    attachment.validationError = false;
    this.attachment = attachment;
    this.attachmentChange.emit(this.attachment);

    }



  delete() {
    this.busy('Removing file...');
    const id = this.attachment.obsAssessmentID
    this.observationService.DeleteAttachment(id).toPromise().then(() => {
      this.notBusy();
      this.attachment.displayFilename = null;
      this.attachment.filename = null;
      this.updateAttachment(new AssessmentAttachment(null, this.attachment.obsAssessmentID));
    }).catch(() => {
      this.notBusy();
      this.openSnackBar('Error: The observation document was not delted.', null);
    });
  }

  private openSnackBar(data: string, status: string) {
    this.snackbar.open(data, status, {
      duration: 5 * 1000,
    });
  }

}
