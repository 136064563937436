import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldSupervisor } from '../../shared/models/fsupervisor.model';
import { FSupervisorService } from '../../services/supervisor.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource  } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';

@Component({
    selector: 'app-select-supervisor',
    templateUrl: './select-supervisor.component.html',
    styleUrls: ['./select-supervisor.component.scss']
})
export class SelectSupervisorComponent implements OnInit {

    displayedColumns: string[] = ['FieldSupervisorId', 'FirstName', 'LastName', 'PrimaryEmail'];
    supervisors: FieldSupervisor[] = [];
    dataSource: any;
    showLoading = false;
    loggedUserEmail: string;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private supervisorService: FSupervisorService,
        private authService: AuthService,
        private router: Router,
        private globalService: GlobalService) {
    }

    ngOnInit() {
        if (this.authService.authenticated) {
            this.authService.getUser().then(user => {                
                console.log(user);
            });            
        }
        else {
            this.router.navigate(['auth']);
        }
        this.searchSupervisors();
    }


    private searchSupervisors() {
        this.showLoading = true;
        this.supervisorService.getAvailableFieldSupervisors().subscribe(data => {

            //find logged user email in supervisor
            let findSupervisor = data.find(x => x.PrimaryEmail === this.loggedUserEmail)
            if (findSupervisor != undefined) {
                //TODO: Check roles

                //Set global variable supervisor
                this.globalService.supervisor = findSupervisor;                

                //Indicate this is NOT an impersonation
                this.globalService.impersonated = false;

                //Redirect to intern search
                this.router.navigate(['intern-search']);               
            }

            this.supervisors = data;
            this.dataSource = new MatTableDataSource<FieldSupervisor>(this.supervisors);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.showLoading = false;
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();       

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    select(supervisor: any) {
        console.log(supervisor);

        //Set global variable supervisor
        this.globalService.supervisor = supervisor;

        //Indicate this is an impersonation        
        this.globalService.impersonated = true;

        //Redirect to intern search
        this.router.navigate(['intern-search']);
    }
}
