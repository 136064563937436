import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ObsResponses } from 'src/app/shared/models/contactResponses';
import { Helper } from 'src/utility/Helper';

@Component({
  selector: 'app-observation-learning',
  templateUrl: './observation-learning.component.html',
  styleUrls: ['./observation-learning.component.scss']
})
export class ObservationLearningComponent implements OnInit {

  obsPlanning=[{
    "id":"31",
    "initialVal":0,
    "title":"Dimension 3.1: Classroom Environment, Routines and Procedures",
    "desc":"The teacher organizes a safe, accessible, and efficient classroom.",
    "instruction":"<b>The teacher:</b><br><br>•Establishes and reinforces procedures and routines to ensure students’ understanding<br><br>•Creates a system for management of supplies and equipment to facilitate transitions between activities and tasks<br><br>•Provides a safe and organized learning environment that motivates and encourages students’ participation"
  },
  {
    "id":"32",
    "initialVal":3,
    "title":"Dimension 3.2: Managing Student Behavior",
    "desc":"The teacher establishes, communicates, and maintains clear expectations for student behavior.",
    "instruction":"<b>The teacher:</b><br><br>•Establishes a classroom management system that creates an environment that facilitates learning and active participation<br><br>•Communicates classroom behavior expectations by reinforcing established rules and redirecting off-task behaviors and support with clear direction<br><br>•Praises and encourages students to motivate and increase participation"
  },
  {
    "id":"33",
    "initialVal":6,
    "title":"Dimension 3.3: Classroom Culture",
    "desc":"The teacher leads a mutually respectful and collaborative class of actively engaged learners.",
    "instruction":"<b>The teacher:</b><br><br>•Monitors and facilitates groups and individual students to increase interaction and achievement<br><br>•Provides opportunities for students to work collaboratively and respectfully<br><br>•Creates a climate that is conducive for learning"
  }];

  @Input() Responses: ObsResponses[] = [];
  @Output() onLearningDataChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.LoadResponse();
    //this.onLearningDataChange.emit(this.Responses);
  }

  EmitChildData(index: number, type: string){
    //this.onLearningDataChange.emit(this.Responses);    
    const dimensionId: string = `dimension-3.${index+1}-${type}`;
    Helper.removeCssClass(dimensionId, "validation-error");
  }

  LoadResponse(){
    
    let count=29;

    if (this.obsPlanning.length <= 3 && this.Responses.length <= 0) {
      for (let index = 0; index < this.obsPlanning.length; index++) {
        const element = this.obsPlanning[index];

        count += 1;
        let obRes = new ObsResponses();
        obRes.QuestionId = count;
        obRes.ControlType = "Ranking";
        obRes.ResponseValue = "";

        count += 1;
        let obRes1 = new ObsResponses();
        obRes1.QuestionId = count;
        obRes1.ControlType = "FreeTextForm";
        obRes1.ResponseValue = "";

        count += 1;
        let obRes2 = new ObsResponses();
        obRes2.QuestionId = count;
        obRes2.ControlType = "FreeTextForm";
        obRes2.ResponseValue = "";

        this.Responses.push(obRes);
        this.Responses.push(obRes1);
        this.Responses.push(obRes2);

      }
    }
  }

}
