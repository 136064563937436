export enum ObsStatusEnum
{
    Scheduled = 1,
    Saved = 2,
    Submitted = 3,
    Approved = 4,
    Accepted = 5,
    Rejected = 6,
    Deleted = 7,
    Not_Paid = 8,
    Not_Payable = 9,
    Paid = 10,
    Waived = 11,
    Missed = 12,
    Denied = 13
}