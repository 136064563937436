import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { internCertDetails } from 'src/app/shared/models/internCertDetails';

@Component({
  selector: 'app-nontexas-contact',
  templateUrl: './nontexas-contact.component.html',
  styleUrls: ['./nontexas-contact.component.scss']
})
export class NontexasContactComponent implements OnInit {
  
  @ViewChild('StartTime') StartTime: ElementRef<HTMLSelectElement>;
  @ViewChild('EndTime') EndTime: ElementRef<HTMLSelectElement>;
  @ViewChild('ObsDuration') ObsDuration: ElementRef<HTMLInputElement>;

  internId:string;
  showObservation:boolean=true;
  showAlertExp:boolean=false;
  @Input() outlstcert: any;
  lsttime=[];
  lstendtime=[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    console.log('Starting Non-Texas new contact Details view');
    this.internId= this.activatedRoute.snapshot.paramMap.get('id');
    this.loadtimedropdown();
  }

  loadContactTypeChange(curEvt){
    this.showObservation=false;
    if(curEvt.currentTarget.value == 1){
      this.showObservation=true;
    }
  }
  loadAlertChange(curEvt){
    this.showAlertExp=true;
    if(curEvt.currentTarget.value == 0){
      this.showAlertExp=false;
    }
  }

  loadtimedropdown(){
    let starttime=7.00;
    this.lsttime.push('7.00 AM');

    for (let index = 0; index < 46; index++) {
      if(starttime.toString().indexOf('12.45') != -1){
        starttime=1.00;
      }else{
        starttime=starttime + 0.15;
      }
      
      if(starttime.toFixed(2).toString().indexOf('.60') != -1){
        starttime=Math.round(starttime);
      }

      if(starttime >= 1 && starttime <7){
        this.lsttime.push(starttime.toFixed(2) + ' PM');
      }else{
        this.lsttime.push(starttime.toFixed(2) + ' AM');
      }

      starttime=parseFloat(starttime.toFixed(2));
    }
  }

  loadendtimedropdown(event){
    this.lstendtime=[];
    let cureve=event.currentTarget.value;
    let starttime=parseFloat(cureve.substring(0,cureve.indexOf(' ')));
    this.lstendtime.push(cureve);

    for (let index = 0; index < 46; index++) {
      if(starttime.toString().indexOf('12.45') != -1){
        starttime=1.00;
      }else{
        starttime=starttime + 0.15;
      }
      
      if(starttime.toFixed(2).toString().indexOf('.60') != -1){
        starttime=Math.round(starttime);
      }

      if(starttime >= 1 && starttime <7){
        this.lstendtime.push(starttime.toFixed(2) + ' PM');
      }else{
        this.lstendtime.push(starttime.toFixed(2) + ' AM');
      }

      starttime=parseFloat(starttime.toFixed(2));

      if(starttime.toString().indexOf("6.45") != -1){
        return;
      }
    }
  }

  loadduration(){
    let tstart=this.StartTime.nativeElement.value;
    let tend=this.EndTime.nativeElement.value;

    if(tstart && tend){
      let caldur=parseFloat(tend)-parseFloat(tstart);
      this.ObsDuration.nativeElement.value=caldur.toFixed(2).toString()+" hrs";
    }else{
      this.ObsDuration.nativeElement.value="";
    }
    
  }

}
