import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource  } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Subject } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { campus } from 'src/app/shared/models/campus';
import { recommendation } from 'src/app/shared/models/recommendation';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { InternService } from '../../services/intern.service';
import { EmailComponent } from '../../shared/email/email.component';
import { certificate } from '../../shared/models/certificate';
import { EmailModel } from '../../shared/models/email-model';
import { EmailRecipient } from '../../shared/models/email-recipient';
import { FieldSupervisor } from '../../shared/models/fsupervisor.model';
import { internCertDetails } from '../../shared/models/internCertDetails';

@Component({
  selector: 'app-intern-search',
  templateUrl: './intern-search.component.html',
  styleUrls: ['./intern-search.component.scss']
})
export class InternSearchComponent implements OnDestroy, OnInit {

  form = new UntypedFormGroup({});

  @ViewChild('campus') campus: ElementRef<HTMLSelectElement>;
  @ViewChild('obscriteria') obscriteria: ElementRef<HTMLSelectElement>;
  @ViewChild('campuszipcode') campuszipcode: ElementRef<HTMLInputElement>;
  @ViewChild('distance') distance: ElementRef<HTMLInputElement>;
  @ViewChild('recommendation') recommendation: ElementRef<HTMLSelectElement>;
  @ViewChild('comments') comments: ElementRef<HTMLInputElement>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  //List of displayed columns
  standardDisplayedColumns: string[] = ['select', 'reverseFullName', 'districtName', 'campusName', 'assigned', 'trackStart'
    , 'initialContact', 'firstObsDeadLine', 'secondObsDeadLine', 'thirdObsDeadLine'
    , 'recommendationStatus', 'principalRecommendationStatus'];

  defaultDisplayedColumns: string[] = ['select', 'reverseFullName', 'isDistrictSelect', 'districtName', 'campusName', 'assigned', 'trackStart'
    , 'initialContact', 'firstObsDeadLine', 'secondObsDeadLine', 'thirdObsDeadLine'
    , 'forthObsDeadLine', 'fifthObsDeadLine', 'sixthObsDeadLine', 'recommendationStatus', 'principalRecommendationStatus'];

  extendDisplayedColumns: string[] = ['select', 'reverseFullName', 'districtName', 'campusName', 'assigned', 'trackStart'
    , 'initialContact', 'firstObsDeadLine', 'secondObsDeadLine', 'thirdObsDeadLine'
    , 'forthObsDeadLine', 'fifthObsDeadLine', 'sixthObsDeadLine', 'seventhObsDeadLine', 'recommendationStatus', 'principalRecommendationStatus'];

  displayedColumns: string[] = this.defaultDisplayedColumns;

  //Mat datasource to populate intern dashboard table
  dataSource: any;
  //Mat array to handle selection
  selection = new SelectionModel<certificate>(true, []);
  //Used to get data to generate PDF
  lstcertDetails: internCertDetails[] = [];
  //Keeps the original data from API
  lstcert: certificate[] = [];
  //Used to display data after apply filters
  lstcertsearch: certificate[] = [];
  //Flag to display or not loading spinner
  showLoading: boolean = false;
  strUNDEFINED: string = "UNDEFINED";
  isExpansionState: boolean = false;

  supervisor: FieldSupervisor;
  lstCompletedAllObs: certificate[] = [];
  lstNotCompletedAllObs: certificate[] = [];
  internIds: string = '';
  campusIds: string = '';
  lstcampus: campus[] = [];
  lstcertsearchtemp: certificate[] = [];
  dtTrigger: Subject<certificate> = new Subject();
  selectedInternId: number = 0;
  selectedCertificate = new certificate;
  isinternidselected = false;
  checkedInternId = [];
  durationInSeconds = 5;
  today = new Date();
  isDistrictSelectCheckboxChecked: boolean = false;
  showOnlyDistrictSelectInterns: boolean;

  constructor(private internService: InternService, private modalService: ModalService,
    private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar,
    public dialog: MatDialog, private authService: AuthService, private router: Router,
    private globalService: GlobalService) { }

  ngOnInit() {
    if (!this.authService.authenticated) {
      this.router.navigate(['auth']);
    }

    if (this.authService.authenticated) {
      console.log("Global service supervisor", this.globalService.supervisor);
      if (this.globalService.supervisor != undefined) {
        this.supervisor = this.globalService.supervisor;
      }
    }
    this.getInterns();
    this.setupColumns();
    this.refreshTable();
  }

  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Intern List',
    useBom: true,
    noDownload: false,
    headers: ["INTERN NAME", "DIST", "CAMPUS", "ASSIGNED", "TRACK START", "1ST OBS", "2ND OBS", "3RD OBS", "4TH OBS", "5TH OBS", "6TH OBS", "RCMD"]
  };

  downloadCSV() {
    let downloadinternlst = [];
    this.selection.selected.forEach(element => {

      let prepareData = {
        "INTERN NAME": element.reverseFullName,
        "DIST": element.districtName,
        "CAMPUS": element.campusName,
        "ASSIGNED": (this.datePipe.transform(element.assigned, 'MM-dd-yyyy')),
        "TRACK START": (this.datePipe.transform(element.trackStart, 'MM-dd-yyyy')),
        "1ST OBS": (element.firstObsDeadLine != undefined) ? (this.datePipe.transform(element.firstObsDeadLine, 'MM-dd-yyyy')) : "",
        "2ND OBS": (element.secondObsDeadLine != undefined) ? (this.datePipe.transform(element.secondObsDeadLine, 'MM-dd-yyyy')) : "",
        "3RD OBS": (element.thirdObsDeadLine != undefined) ? (this.datePipe.transform(element.thirdObsDeadLine, 'MM-dd-yyyy')) : "",
        "4TH OBS": (element.forthObsDeadLine != undefined) ? (this.datePipe.transform(element.forthObsDeadLine, 'MM-dd-yyyy')) : "",
        "5TH OBS": (element.fifthObsDeadLine != undefined) ? (this.datePipe.transform(element.fifthObsDeadLine, 'MM-dd-yyyy')) : "",
        "6TH OBS": (element.sixthObsDeadLine != undefined) ? (this.datePipe.transform(element.sixthObsDeadLine, 'MM-dd-yyyy')) : "",
        "RCMD": (element.recommendationStatus != null) ? element.recommendationStatus : ""
      }

      downloadinternlst.push(prepareData);
    });

    if (downloadinternlst == null || downloadinternlst.length <= 0) {
      this.openSnackBar("Select one or more interns", "Error");
      return;
    }

    new AngularCsv(downloadinternlst, "InternList", this.csvOptions);
  }

  getInternsSearch() {

    this.showLoading = true;

    if (this.lstcert) {

      this.lstcertsearch = this.lstcert;

      //here we are filtering for observation criteria dropdown
      if (this.obscriteria.nativeElement.value == '0') {
        this.lstcertsearch = this.lstcertsearch;
      } else if (this.obscriteria.nativeElement.value == '2') {
        this.lstcertsearch = this.lstNotCompletedAllObs;
      }
      else if (this.obscriteria.nativeElement.value == '1') {
        this.lstcertsearch = this.lstCompletedAllObs;
      }

      //here we are filtering for campus dropdown
      if (this.campus.nativeElement.value == '0') {
        this.lstcertsearch = this.lstcertsearch;
      } else {
        this.lstcertsearch = this.lstcertsearch.filter(a => a.campusName.toLowerCase() == this.campus.nativeElement.value.toLowerCase());
      }

      //here we are filtering for campuszipcode and distance
      if (this.campuszipcode.nativeElement.value && this.distance.nativeElement.value) {
        this.lstcertsearchtemp = [];
        //validate field
        if (this.campuszipcode.nativeElement.value == '0' || this.distance.nativeElement.value == '0') {
          return false;
        }

        this.internService.getInternByZipcode(this.campuszipcode.nativeElement.value, this.distance.nativeElement.value, this.campusIds.slice(1)).subscribe(data => {

          this.lstcertsearch.forEach(cert => {
            data.forEach(item => {
              if (cert.campusId == item) {
                let isAdded = this.lstcertsearchtemp.find(x => x.campusId == item)
                if (!isAdded) {
                  this.lstcertsearchtemp.push(cert);
                }
              }
            })
          });

          this.lstcertsearch = this.lstcertsearchtemp;
          this.refreshTable();
        });
      }
    }

    if (!this.isExpansionState) {
      if (this.showOnlyDistrictSelectInterns) {
        this.isDistrictSelectCheckboxChecked = true;
        this.lstcertsearch = this.lstcertsearch.filter(rec => rec.isDistrictSelect == true);
      }
    }

    this.refreshTable();

    this.showLoading = false;
  }

  getInterns() {

    this.showLoading = true;

    this.internService.getCertificatesBySupervisor(this.supervisor.FieldSupervisorId).subscribe(data => {

      if (data == undefined) {
        this.showLoading = false;
        return;
      }

      //sort apply default sort
      this.lstcert = data.sort((a, b) => a.lastName.localeCompare(b.lastName));

      this.lstcertsearch = this.lstcert;

      this.dataSource = new MatTableDataSource<certificate>(this.lstcert);

      //this.lstcertsearch = this.lstcert;
      //here we are loading campus data in dropdown
      this.lstcert.forEach(element => {
        var camp = new campus();
        camp.Name = element.campusName;
        camp.CampusId = element.campusId;
        camp.DistrictName = element.districtName;

        const isCampAvail = this.lstcampus.find(c => c.Name == camp.Name);
        if (!isCampAvail && camp.CampusId > 0) {

          this.lstcampus.push(camp);
          this.campusIds = this.campusIds + ',' + camp.CampusId;
        }

        this.internIds = this.internIds + ',' + element.internNumber;

        //below logic is done to load data for observation criteria dropdown.
        if (element.allObsAreCompleted) {
          this.lstCompletedAllObs.push(element);
        } else {
          this.lstNotCompletedAllObs.push(element);
        }
      });

      //Sort list of campus
      if (this.lstcampus.length > 0) {
        this.lstcampus = this.lstcampus.sort((a, b) => a.Name.localeCompare(b.Name));
      }

      this.dataSource.sort = this.sort;

      this.showLoading = false;
    });
  }

  setupColumns() {

    this.internService.getSupervisorCertifiedStates(this.supervisor.FieldSupervisorId).subscribe(states => {

      if (states.findIndex(x => x.StateName == 'Michigan') >= 0) {
        this.displayedColumns = this.extendDisplayedColumns;
        this.isExpansionState = true;
      }
      else if (states.findIndex(x => x.StateName == 'Texas') >= 0) {
        this.displayedColumns = this.defaultDisplayedColumns;
        this.isExpansionState = false;
      }
      else {
        this.displayedColumns = this.standardDisplayedColumns;
        this.isExpansionState = true;
      }
    });
  }

  reset() {

    this.campuszipcode.nativeElement.value = '';
    this.distance.nativeElement.value = '';
    this.obscriteria.nativeElement.selectedIndex = 0;
    this.campus.nativeElement.selectedIndex = 0;

    // clear the checkbox selection
    this.showOnlyDistrictSelectInterns = false;
    this.isDistrictSelectCheckboxChecked = false;

    this.getInternsSearch();
    this.selection.clear();
  }

  ngOnDestroy(): void {

  }

  closeNotification() {
    $("#dvnotify").hide();
  }

  sendEmail() {

    if (this.selection.selected.length > 0) {

      // create email model to pass as a data to email component
      let mailModel = new EmailModel;

      //Sending all posible emails for selection
      this.lstcert.forEach(intern => {
        let recipient = new EmailRecipient();
        recipient.email = intern.emailAddress;
        recipient.name = intern.firstName + ' ' + intern.lastName;
        recipient.profileId = intern.profileId;
        mailModel.allRecipients.push(recipient);
      });

      //Send list of selected interns
      this.selection.selected.forEach(selectedIntern => {
        let recipient = new EmailRecipient();
        recipient.email = selectedIntern.emailAddress;
        recipient.name = selectedIntern.firstName + ' ' + selectedIntern.lastName;
        recipient.profileId = selectedIntern.profileId;
        mailModel.bcc.push(recipient);
      });

      //Map sender
      let fromRecipient = new EmailRecipient();
      mailModel.from = fromRecipient;

      //Open email Modal component
      const dialogRef = this.dialog.open(EmailComponent, {
        width: '800px',
        height: '750px',
        minHeight: '400px',
        minWidth: '600px',
        data: mailModel
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.openSnackBar("Email Sent", "Successfully");

        }
      });
    }
    else {
      this.openSnackBar("Select one or more interns", "Error");
    }
  }

  AddRcmd() {

    this.modalService.close('interns-recommendation');

    let newRcmd = new recommendation();

    newRcmd.CertProgramId = this.selectedCertificate.certProgramID;
    newRcmd.RecommendationTypeId = +this.recommendation.nativeElement.value;
    newRcmd.Comment = this.comments.nativeElement.value;

    if (this.recommendation.nativeElement.value == '1') {
      this.selectedCertificate.recommendationStatus = 'Certify';
    }
    else if (this.recommendation.nativeElement.value == '2') {
      this.selectedCertificate.recommendationStatus = 'Extend';
    }
    else if (this.recommendation.nativeElement.value == '3') {

      this.selectedCertificate.recommendationStatus = 'Not Certify';
    }

    newRcmd.Date = new Date().toDateString();
    newRcmd.CreateUser = this.selectedCertificate.firstName;
    newRcmd.FsLastName = this.supervisor.FirstName;
    newRcmd.FsLastName = this.supervisor.LastName;
    newRcmd.CoachTypeId = 1;


    console.log("Before call service with new typeid: " + newRcmd.RecommendationTypeId.toString() + " CertID: " + newRcmd.CertProgramId.toString());
    this.internService.AddRecommendation(newRcmd).subscribe(rcmd => { console.log(rcmd.CertProgramId.toString()) });
  }

  closeRecModal() {
    this.modalService.close('interns-recommendation');
  }

  openrecommendation(id: number) {
    console.log('opening add recommendation for id: ' + id);
    this.selectedInternId = id;

    let certInfo = this.lstcertsearch
      .find(x => x.internNumber == this.selectedInternId);

    if (certInfo) {
      console.log('object found!');
      this.selectedCertificate = certInfo;
    }

    this.modalService.open('interns-recommendation');
  }

  ChangeClassByCondition(statusCode, deadline) {

    let className = "defaultCellBackground";

    if (statusCode == null) {
      return className;
    }

    switch (statusCode) {
      case "DateValue": {
        const differenceInDays = this.getDifference(deadline, this.today);
        if (differenceInDays <= 28) {
          className = 'next-four-weeks'
        }
        if (differenceInDays < 0) {
          className = 'past-due'
        }
        break;
      }
      case "Tardy": {
        className = 'tardy';
        break;
      }
      case "Approved": {
        className = 'approved';
        break;
      }
      case "Missed": {
        className = 'missed';
        break;
      }
      default: {
        break;
      }
    }

    return className;

  }

  IsObservationComplete(statusCode){
    let validCompleteStatus = ['Approved', 'Accepted', 'Waived', 'Missed'];
    return validCompleteStatus.includes(statusCode);
  }

  GetObservationStatus(statusCode, endDate, scheduledDate) {

    let hasEndDate = endDate !== null || endDate !== undefined;
    if (this.IsObservationComplete(statusCode) == false) {
      if (hasEndDate && statusCode != 'Scheduled') {
        return statusCode;
      }
      else {
        return this.datePipe.transform(scheduledDate, 'MM/dd/yyyy');
      }
    }
  }

  ChangeClassByAssignedDate(assigned) {
    let className = "defaultCellBackground";
    const differenceInDays = this.getDifference(this.today, assigned);
    if (differenceInDays <= 14) {
      className = 'new-intern'
    }
    return className;
  }

  getDifference(date1: any, date2: any) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    return (date1.getTime() - date2.getTime()) / (1000 * 3600 * 24);
  }

  //Generates a snackBar notification
  openSnackBar(data: string, status: string) {
    this._snackBar.open(data, status, {
      duration: this.durationInSeconds * 1500,
    });
  }

  async exportToPdf() {

    if (this.selection != null && this.selection.selected.length > 0) {
      this.getDataToPDF();

      await this.delay(3000);
      this.openSnackBar("Generating PDF", "")
      this.generatePDF();
    }
    else {
      this.openSnackBar("Select one or more interns", "Error");
    }
  }

  getDataToPDF() {

    if (this.selection != null && this.selection.selected.length > 0) {

      let selectedIds: string = ",";

      this.selection.selected.forEach(element => {
        selectedIds += element.internNumber + ',';
      });

      this.internService.getSelectedInternDetails(selectedIds).subscribe(data => {
        this.lstcertDetails = data;
      });
    }
    else {
      this.openSnackBar("Select one or more interns", "Error");
    }
  }

  generatePDF() {
    var data = document.getElementById('internDataPDF');
    data.style.display = "block";

    html2canvas(data).then(canvas => {

      // Few necessary setting options
      var imgWidth = 195;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 10;
      pdf.addImage(contentDataURL, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save('InterDetails.pdf'); // Generated PDF
      data.style.display = "none";
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  addDays(date: Date, days: number) {
    let result = new Date(date);
    return result.setDate(result.getDate() + days);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter(row => row.certType != this.strUNDEFINED).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => row.certType != this.strUNDEFINED && this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: certificate): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.internNumber + 1}`;
  }

  refreshTable() {
    //Refresh Table
    this.dataSource = new MatTableDataSource<certificate>(this.lstcertsearch);
    this.dataSource.sort = this.sort;
  }

  IsCertTypeUndefined(row: certificate) {
    return row.certType == this.strUNDEFINED;
  }

  IsRowBroken(row: certificate) {
    var isBroken: Boolean = false;
    isBroken = isBroken || this.IsCertTypeUndefined(row);
    return isBroken;
  }

  GetBrokenRowMessages(row: certificate) {
    var errmsg: string = "";

    if (this.IsRowBroken(row)) {
      errmsg += this.IsCertTypeUndefined(row) ? "CertType is UNDEFINED. " : "";
    }

    return errmsg;
  }

  onChange($event) {
    if ($event.checked) {
      this.showOnlyDistrictSelectInterns = true;
      this.isDistrictSelectCheckboxChecked = true;
     }
    else {
      this.showOnlyDistrictSelectInterns = false;
      this.isDistrictSelectCheckboxChecked = false;
      }
    $event.source.focus();
  }
}


