// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-list {
  margin-bottom: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/intern/observation/observation-form-error-sheet/observation-form-error-sheet.component.scss"],"names":[],"mappings":"AAAA;EAEE,mBAAA;AAAF","sourcesContent":["mat-list\n {\n  margin-bottom: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
