export class Authorization_GetRoles_Options {
  AuthenticatedEmailAddress: string = null;
}

export class AuthenticatedAndAuthorizedUser {
  EmailAddress: string = null;
  FirstName: string = null;
  LastName: string = null;
  UserGUID: string = null;
  IsCoachImpersonator: boolean = false;
  IsInternImpersonator: boolean = false;
  UserID: string = null;
  Authorization_Roles: Authorization_Role[];
}

export class Impersonation_AddToImpersonationLog_Options {
  UserEmail: string = null;
  TargetEmail: string = null;

  Type: string = null;
}

export class Authorization_Role_List {
  Authorization_Roles: Authorization_Role[];
}
export class Authorization_Role {
  AuthorizationRoleID: string = null;
  AuthorizationRoleName: string = null;
}
