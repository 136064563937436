import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { User } from '../shared/models/user';
import { GlobalService } from '../services/global.service';
import { FSupervisorService } from '../services/supervisor.service';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  isLoading = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private maslService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private supervisorService: FSupervisorService,
    private globalService: GlobalService,
    private authService: AuthService,
    private router: Router
  ) { }


  ngOnInit() {
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    )
    .subscribe((result: EventMessage) => {
      console.log('auth ngOnInit login success event result',result);
      console.log('auth ngOnInit login success event account',result.payload);
      if (result?.payload as AccountInfo) {
        this.maslService.instance.setActiveAccount(result.payload as AccountInfo);
        this.updateLoginStatus();
      }
    });

    this.updateLoginStatus();
  }


  updateLoginStatus() {
    const accountExists = this.maslService.instance.getAllAccounts().length > 0;
    this.isLoading = accountExists;
    if (accountExists && this.authService.authenticated == false){
      this.authService.initializeUser(this.maslService.instance.getAllAccounts()[0])
      .then((user)=> this.GoSomewhere(user))
      .catch(error => console.error(error));
    }
  }

  GoSomewhere(user: User) {
    let theRoles = user.roles;
    console.log("Going somewhere with roles ", theRoles);
    for (var i = 0; i < theRoles.length; i++) {
      if (theRoles[i].AuthorizationRoleName == "Coach_Impersonator") {
        console.log("first role loop", theRoles[i].AuthorizationRoleName);
        this.router.navigate(['select-supervisor']);
      }
    }

    for (var i = 0; i < theRoles.length; i++) {
      if (theRoles[i].AuthorizationRoleName == "Coach") {
        //Set global variable supervisor
        this.supervisorService.getFieldSuperisorbyEmail(user.email).subscribe(data => {
          console.log("my supervisor", data);
          this.globalService.supervisor = data;
          this.router.navigate(['intern-search']);
        });
      }
    }
  }

  signIn() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest){
        this.maslService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.maslService.instance.setActiveAccount(response.account);
          });
        } else {
          this.maslService.loginPopup()
            .subscribe((response: AuthenticationResult) => {
              this.maslService.instance.setActiveAccount(response.account);
            });
      }
    } else {
      if (this.msalGuardConfig.authRequest){
        this.maslService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.maslService.loginRedirect();
      }
    }
  }

}
