import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'msal-redirect',
  templateUrl: './msal-redirect.component.html',
  styleUrls: ['./msal-redirect.component.scss']
})
export class MsalRedirectComponent implements OnInit{
  constructor(private authService: MsalService){}
  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();
  }

}
