export enum ObsQuestionGrowthStateEnum {
    OverallImpression = 1,
    PreConferenceDate = 2,
    PreStartTime = 3,
    PreEndTime = 4,
    PostConferenceDate = 5,
    PostStartTime = 6,
    PostEndTime = 7,
    Contact = 8,
    TeachersOfTomorrowPendingRequirements = 51,
    Strengths = 60,
    AreasOfGrowth = 61,
    ActionableSteps = 62,
    OtherNotes = 63,
}