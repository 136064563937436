export class campus {
  CampusId: number = 0;
  Name: string = null;
  DistrictId: number = 0;
  DistrictName: string = null;
  StreetAddress: string = null;
  City: string = null;
  State: string = null;
  ZipCode: string = null;
  Phone: number = 0;
  PrincipalName: string = null;
  PrincipalLastName: string = null;
  PrincipalPhoneNumber: number = 0;
  PrincipalEmailAddress: string = null;
  PrincipalFirstName: string = null;
  CampusSupervisorName: string = null;
  CampusSupervisorEmail: string = null;
}
