import { VisitationFormatEnum } from '../enums/visitation-format.enum';
import { ObsResponses } from './contactResponses';
import { formatDate } from '@angular/common';

export class contactObservation {
  AssessmentId: number;
  ObservationIdentifier: string = "0";
  ObservationSortOrder: number;
  Name: string = formatDate(new Date().toString(), 'mm/dd/yyyy', 'en');
  Subject: string;
  Date: string;
  StartTime: string = "0";
  EndTime: string = "0";
  MileageId: number = 0;
  CampusId: number;
  DistrictId: number;
  Responses: ObsResponses[];
  AttachmentFilename: string;
  AttachmentDate: Date;
  VisitationFormatId: VisitationFormatEnum = VisitationFormatEnum.NotSelected;
  ObservationNumberText: string;
  InPersonRestricted: boolean;
}
