import { Component, OnInit } from '@angular/core';
import { FieldSupervisor } from '../models/fsupervisor.model';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  

    constructor(private authService: AuthService,
      private msalService: MsalService,
        private router: Router,
        private globalService: GlobalService) { }

    ngOnInit() {
      if (!this.authService.authenticated) {
        this.router.navigate(['auth']);
      }      
    }

    signOut(){
      this.msalService.logout();
      this.router.navigate(['auth']);
    }
}
