import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Assessment } from 'src/app/shared/models/assessment';
import { InformalObservationState } from 'src/app/shared/models/informal-observation-state.component';

@Component({
  selector: 'app-informal-observation',
  templateUrl: './informal-observation.component.html',
  styleUrls: ['./informal-observation.component.scss']
})
export class InformalObservationComponent implements OnInit, AfterViewInit {
  obsAssessment: Assessment;
  loadDuration: Function;
  getTimeIn24HourFormat: (timeIn12HourFormat: string) => string;

  @ViewChild('ObsDuration') ObsDuration: ElementRef<HTMLInputElement>;
  @Input() state: InformalObservationState;
  @Output() onInformalObsStateChange: EventEmitter<InformalObservationState> = new EventEmitter<InformalObservationState>();
  
  observationStartTime: string;
  observationEndTime: string;

  ngOnInit() { 
    this.loadDuration = this.state.loadDuration;
    this.getTimeIn24HourFormat = this.state.getTimeIn24HourFormat;
    this.obsAssessment = this.state.obsAssessment;
    this.observationStartTime = this.getTimeIn24HourFormat(this.obsAssessment.observation.StartTime);
    this.observationEndTime = this.getTimeIn24HourFormat(this.obsAssessment.observation.EndTime);
  }

  async ngAfterViewInit(): Promise<void> {
    this.loadDuration();
  }

  onStartTime_Changed() {
    const startTimeElement = (<HTMLInputElement>document.getElementById("start-time"));
    this.obsAssessment.observation.StartTime = startTimeElement.value;

    this.loadDuration();
    this.onObservationChange();
  }

  onEndTime_Changed() {
    const startTimeElement = (<HTMLInputElement>document.getElementById("end-time"));
    this.obsAssessment.observation.EndTime = startTimeElement.value;

    this.loadDuration();
    this.onObservationChange();
  }

  onObservationChange() {
    this.state.isdirty = true;
    this.onInformalObsStateChange.emit(this.state);
  }
}
