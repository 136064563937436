import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import { MatChipInputEvent} from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AuthService } from '../../services/auth.service';
import { EmailService } from '../../services/email.service';
import { FileAttachment } from '../models/attachment';
import { CommunicationLog } from '../models/communication-log';
import { EmailModel } from '../models/email-model';
import { EmailRecipient } from '../models/email-recipient';
import { FieldSupervisor } from '../models/fsupervisor.model';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  recipientCtrl = new UntypedFormControl();
  filteredRecipients: EmailRecipient[];
  supervisor: FieldSupervisor;
  mailData: EmailModel;
  token: string;
  log: CommunicationLog[] = [];

  @ViewChild('recipientInput') recipientInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  subject = new UntypedFormControl('');
  priority = new UntypedFormControl();
  emailContent = new UntypedFormControl('');
  htmlContent = '';
  fileInput: any;
  invalidAttachment = false;
  maxFileSizeAllowed = 50000000;
  currentfileSize: number = 0;
  invalidFileSize = false;
  attachments = [];
  fileList = '';
  maxFileList = '';


  constructor(public dialogRef: MatDialogRef<EmailComponent>, @Inject(MAT_DIALOG_DATA) public data: EmailModel,
    private emailService: EmailService, private authService: AuthService) {
    //initialize mail data
    this.data.sent = false;
    this.mailData = data;
    this.filteredRecipients = this.mailData.bcc;
  }

  ngOnInit() {
    //get auth token from authService
    this.authService.getAccessToken().then(token => {
      this.token = token;
    });
  }

  close() {
    this.dialogRef.close();
  }

  add(event: MatChipInputEvent): void {
    // Add recipient only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add a recipient
      if ((value || '').trim()) {
        //this.recipients.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.recipientCtrl.setValue(null);
    }
  }

  remove(recipient: EmailRecipient): void {
    const index = this.mailData.allRecipients.indexOf(recipient);
    if (index >= 0) {
      this.mailData.allRecipients.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    //this.recipients.push(event.option.viewValue);
    this.recipientInput.nativeElement.value = '';
    this.recipientCtrl.setValue(null);
  }

  private _filter(value: EmailRecipient): EmailRecipient[] {
    const filterValue = value.name.toLowerCase();

    return this.mailData.allRecipients.filter(recipient => recipient.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public async sendEmails() {

    let self = this;

    let newMailData = new EmailModel;
    newMailData.bcc = this.mailData.bcc;
    newMailData.cc = this.mailData.cc;
    newMailData.body = this.mailData.body;

    //Populate data from
    newMailData.subject = this.subject.value;
    newMailData.htmlContent = this.htmlContent;
    newMailData.highImportance = this.priority.value;
    newMailData.allRecipients = [];

    newMailData.saveToSentFolder = true;
    newMailData.processReplacements = true;
    newMailData.emailPerReceipient = true;

    //Add Attachments
    if (this.attachments != null && this.attachments.length > 0) {
      newMailData.hasAttachments = true;
      newMailData.attachments = [];
      for (var i = 0; i < self.attachments.length; i++) {

        let fileSource = self.attachments[i];

        let attachment = new FileAttachment();
        attachment.size = fileSource.size;
        attachment.contentType = fileSource.type;

        attachment.contentBytes = await this.readUploadedFileAsText(fileSource);

        attachment.name = fileSource.name;
        attachment.lastModifiedDateTime = fileSource.lastModifiedDate;
        attachment.contentId = fileSource.name;
        newMailData.attachments.push(attachment);
      }
    }

    //validate if there is a valid token to send the email
    if (this.token.length > 0) {
      //Calling service to send emails      
      this.emailService.SendEmail(newMailData, this.token).subscribe(data => {
        this.dialogRef.close(data);
      });
    }
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '100px',
    maxHeight: '150px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'arial',
    defaultFontSize: '2',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    uploadWithCredentials: true,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript'
      ],
      [
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],

  };

  public fileSelectedChange(event: any) {
    this.currentfileSize = 0;
    this.invalidAttachment = false;
    this.invalidFileSize = false;
    this.fileInput = event.target;   

    this.validateFilesSize(this.fileInput.files);

    if (this.attachments.length > 4) {
      this.invalidAttachment = true;
    }
  }

  private validateFilesSize(files: any) {    

    for (var i = 0; i < files.length; i++) {

      this.attachments.push(files[i]);     

      this.currentfileSize = this.currentfileSize + files[i].size;

      if (this.attachments.length > 4) {
        this.fileList = `${this.maxFileList} ( ${(this.attachments.length - 4)} ) files more...`;
      }
      else if (this.attachments.length == 4) {
        this.fileList = this.fileList + files[i].name + ' | ';
        this.maxFileList = this.fileList;
      } else {
        this.fileList = this.fileList + files[i].name + ' | ';
      }

      if (files[i].size > this.maxFileSizeAllowed) {
        this.invalidFileSize = true;
      }
    }

    if (this.currentfileSize >= this.maxFileSizeAllowed) {
      this.invalidFileSize = true;
    }   
  }

  private readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        var b64 = temporaryFileReader.result.toString().replace(/^data:.+;base64,/, '');
        resolve(b64);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };
}
