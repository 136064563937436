import { Injectable } from '@angular/core';
import { FieldSupervisor } from '../shared/models/fsupervisor.model';

@Injectable({
    providedIn: 'root'
})

//This service allows to save global variables using session storage to preserve the values
export class GlobalService {

    //Private Members

    private _supervisor: FieldSupervisor;
    private _impersonated: boolean;

    //Constructor

    constructor() {        
    }

    // Properties

    get supervisor(): FieldSupervisor {
        this._supervisor = JSON.parse(sessionStorage.getItem('supervisor'));
        return this._supervisor;
    }

    set supervisor(value: FieldSupervisor) {
        this._supervisor = value;
        sessionStorage.setItem('supervisor', JSON.stringify(this._supervisor));
    }

    get impersonated(): boolean {
        this._impersonated = JSON.parse(sessionStorage.getItem('impersonated'));
        return this._impersonated;
    }

    set impersonated(value: boolean) {
        this._impersonated = value;
        sessionStorage.setItem('impersonated', JSON.stringify(this._impersonated));
    }

    clear() {
        sessionStorage.clear();
    }
}
