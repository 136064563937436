// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fs-footer {
  padding: 12px;
  font-size: 12px;
  flex-shrink: 0;
  height: 250px;
}

.fs-footer-copyright {
  font-size: 0.75rem;
  letter-spacing: 1.57px;
  line-height: 19px;
  text-align: right;
}

.fs-footer-logo {
  height: 30px;
}

.fs-footer-icons {
  cursor: pointer;
  height: 26px;
  margin-right: 10px;
}

.footer-social-icon {
  height: 20px;
  padding-left: 4px;
}

.spacer {
  flex: 1 1 auto;
}

.fs-timestamp {
  font-size: 0.625rem;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,mBAAA;EACA,iBAAA;AACF","sourcesContent":[".fs-footer {\n  padding: 12px;\n  font-size: 12px;  \n  flex-shrink: 0;\n  height:250px;\n}\n\n.fs-footer-copyright {  \n  font-size: 0.75rem;\n  letter-spacing: 1.57px;\n  line-height: 19px;\n  text-align: right;\n}\n\n.fs-footer-logo {\n  height: 30px;\n}\n\n.fs-footer-icons {\n  cursor: pointer;\n  height: 26px;\n  margin-right: 10px;\n}\n\n.footer-social-icon {\n  height: 20px;\n  padding-left: 4px;\n}\n\n.spacer {\n  flex: 1 1 auto;\n}\n\n.fs-timestamp {\n  font-size: 0.625rem;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
