export class ObsResponses{
    constructor(){
        this.QuestionId = 0;
        this.ResponseValue="";
    }
    QuestionId: number;
    DomainSortOrder: number;
    QuestionsSortOrder: number;
    ControlType: string;
    Question: string;
    ResponseValue: string;
}