import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { RecipientTypeEnum } from 'src/app/shared/enums/recipient-type.enum';
import { Assessment } from 'src/app/shared/models/assessment';
import { ConferenceState } from 'src/app/shared/models/conference-state.model';
import { Helper } from '../../../utility/Helper';

@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.scss']
})
export class ConferenceComponent {
  obsAssessment: Assessment;
  loadDuration: Function;
  getTimeIn24HourFormat: (timeIn12HourFormat: string) => string;

  @ViewChild('ObsDuration') ObsDuration: ElementRef<HTMLInputElement>;
  @Input() state: ConferenceState;
  @Output() onConferenceStateChange: EventEmitter<ConferenceState> = new EventEmitter<ConferenceState>();
  
  conferenceStartTime: string;
  conferenceEndTime: string;
  collaborators = [
    { id: RecipientTypeEnum.Intern, name: 'Intern', checked: false },
    { id: RecipientTypeEnum.Principal, name: 'Principal', checked: false },
    { id: RecipientTypeEnum.Mentor, name: 'Mentor', checked: false },
    { id: RecipientTypeEnum.CampusSupervisor, name: 'Campus Supervisor', checked: false },
    { id: RecipientTypeEnum.Other, name: 'Other', checked: false }
  ];
  otherCollaboratorDetail: string = '';
  showOtherCollaboratorSpecification: boolean;

  ngOnInit() { 
    this.loadDuration = this.state.loadDuration;
    this.getTimeIn24HourFormat = this.state.getTimeIn24HourFormat;
    this.obsAssessment = this.state.obsAssessment;
    this.conferenceStartTime = this.getTimeIn24HourFormat(this.obsAssessment.observation.StartTime);
    this.conferenceEndTime = this.getTimeIn24HourFormat(this.obsAssessment.observation.EndTime);
    this.loadSelectedCollaborators();
  }

  async ngAfterViewInit(): Promise<void> {
    this.loadDuration();
  }

  onVisitationFormatChanged() {
    Helper.removeCssClass("visitation-option", "validation-error");
    this.onConferenceChange();
  }

  onStartTime_Changed() {
    Helper.removeCssClass("start-time", "validation-error");
    const startTimeElement = (<HTMLInputElement>document.getElementById("start-time"));
    this.obsAssessment.observation.StartTime = startTimeElement.value;

    this.loadDuration();
    this.onConferenceChange();
  }

  onEndTime_Changed() {
    Helper.removeCssClass("end-time", "validation-error");
    const startTimeElement = (<HTMLInputElement>document.getElementById("end-time"));
    this.obsAssessment.observation.EndTime = startTimeElement.value;

    this.loadDuration();
    this.onConferenceChange();
  }

  onNotesChanged() {
    Helper.removeCssClass("notes", "validation-error");
    this.onConferenceChange();
  }

  onAcknowledgementChanged() {
    Helper.removeCssClass("acknowledge-text", "validation-error-text");
    this.onConferenceChange();
  }

  onConferenceChange() {
    this.state.isDirty = true;
    this.onConferenceStateChange.emit(this.state);
  }

  onCollaboratorChange(index: number, event: any) {
    Helper.removeCssClass("collaborators", "validation-error");
    const selectedCollaborators = this.getSelectedCollaborators();
    this.showOtherCollaboratorSpecification = false;
    if (selectedCollaborators.length > 0 && this.collaborators.find(collaborator => collaborator.id === RecipientTypeEnum.Other)?.checked) {
      this.showOtherCollaboratorSpecification = true;
    }
    this.state.collaborators = selectedCollaborators;
    this.onConferenceChange();
  }

  getSelectedCollaborators() {
    return this.collaborators.filter(c => c.checked).map(c => c.id);
  }

  private loadSelectedCollaborators() {
    if(!this.state.collaborators || this.state.collaborators.length == 0)
      return;

    this.state.collaborators.forEach(collaborator => {
      this.collaborators.filter(c => c.id == collaborator)[0].checked = true;
    });

    if(this.collaborators[4].checked) {
      this.showOtherCollaboratorSpecification = true;
    }
  }
}
