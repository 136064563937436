import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lockout-notification',
  templateUrl: './lockout-notification.component.html',
  styleUrls: ['./lockout-notification.component.scss']
})
export class LockoutNotificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
