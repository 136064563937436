export class AssessmentAttachment {
  filename: string;
  obsAssessmentID: number;
  displayFilename: string;
  validationError: boolean;

  constructor(filename: string, assessmentID: number) {
    this.filename = filename;
    this.obsAssessmentID = assessmentID;
    this.displayFilename = this.filename ? this.filename.replace(/^\d+-/, "") : undefined;
    this.validationError = false;
  }

}
